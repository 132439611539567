// Define the months and corresponding days in a leap year
const months = {
    "January": 31,
    "February": 29,  // 29 days in February during a leap year
    "March": 31,
    "April": 30,
    "May": 31,
    "June": 30,
    "July": 31,
    "August": 31,
    "September": 30,
    "October": 31,
    "November": 30,
    "December": 31
};

// Helper function to get the day suffix
function getDaySuffix(day) {
    if ((day >= 4 && day <= 20) || (day >= 24 && day <= 30)) {
        return "th";
    } else {
        const suffixes = { 1: "st", 2: "nd", 3: "rd" };
        return suffixes[day % 10] || "th";
    }
}

function getDaysLong() {
    // Create the list of days
    const days_long: string[] = [];

    for (const month in months) {
        const days = months[month];
        for (let day = 1; day <= days; day++) {
            const daySuffix = getDaySuffix(day);
            days_long.push(`${month} ${day}${daySuffix}`);
        }
    }

    return days_long;
}

export { getDaysLong }