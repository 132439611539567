import { Group, Text, Accordion } from '@mantine/core';
import React, { } from 'react';
import { isMobile } from 'react-device-detect';
import { IconFlask, IconBinoculars } from '@tabler/icons-react';
import { getDynamicText, getExtraText } from './variableDescriptions.ts';
import { RootState } from '../reducers/store.ts';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

interface AccordionLabelProps {
    label: string;
    image: React.ReactNode;
    description: string;
}

const lineHeight = "80%"
const myStyle = { lineHeight: lineHeight, marginBottom: "0.5em" }

function AccordionLabel({ label, image, description }: AccordionLabelProps) {

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const multipanel = useTypedSelector(state => state.graph.multipanel)

    return (
        <Group wrap="nowrap">
            {image}
            <div>
                <Text style={(!isMobile && !multipanel) ? myStyle : {}}>{label}</Text>
                {(!isMobile && !multipanel) &&
                    <Text style={{ lineHeight: "100%" }} size="md" c="dimmed" fw={400}>
                        <i>{description}</i>
                    </Text>
                }
            </div>
        </Group>
    );
}

function AccordionGraphs({ variable, period }) {
    const accordionInfo = [
        {
            id: 'what',
            image: <IconFlask />,
            label: "What's shown?",
            description: 'How this graph was made...',
            content: getDynamicText(variable, period),
        },

        {
            id: 'explore',
            image: <IconBinoculars />,
            label: 'Explore',
            description: 'How do different locations compare?',
            content: getExtraText(variable),
        },
    ];

    const items = accordionInfo.map((item) => (
        <Accordion.Item value={item.id} key={item.label} className='fullWidth'>
            <Accordion.Control>
                <AccordionLabel {...item} />
            </Accordion.Control>
            <Accordion.Panel>
                <Text size="md" dangerouslySetInnerHTML={{ __html: item.content }} />
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Accordion chevronPosition="right" variant="contained">
            {items}
        </Accordion>
    );
}

export default AccordionGraphs;