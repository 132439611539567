function normalizeLongitude(longitude) {
    // Normalize the longitude to be within the range of -180 to 180
    longitude = ((longitude + 180) % 360 + 360) % 360 - 180;
    return longitude;
}

function formatLocation(latitude, longitude) {
    // Determine N/S for latitude
    let latDirection = latitude >= 0 ? 'N' : 'S';
    let formattedLat = Math.abs(latitude).toFixed(1) + latDirection;
    
    // Determine E/W for longitude
    let lonDirection = longitude >= 0 ? 'E' : 'W';
    let formattedLon = Math.abs(longitude).toFixed(1) + lonDirection;
    
    // Combine into a single string
    return `${formattedLat}, ${formattedLon}`;
}

export { normalizeLongitude, formatLocation } 