import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../reducers/store.ts';
import React, { useEffect, memo, useState } from 'react';
import { isMobile } from 'react-device-detect';

function ColorBars() {
    useEffect(() => {
        console.log("Rendered ColorBars")
    })

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const prefix = spectralPalette ? 'spectral' : 'mono'
    const orientation = isMobile ? 'vertical' : 'horizontal'

    const imageName = prefix + '_' + orientation  + '_colorBar.png'

    const baseStyle: React.CSSProperties = {
        position: "fixed",
        pointerEvents: "none",
        overflow: 'visible',
    }
    
    const barLength = 300
    const barWidth = 60

    const styleVertical: React.CSSProperties = {
        top: `${windowSize.height - 30 - barLength}px`,
        right: "10px",
    }

    const styleHorizontal: React.CSSProperties = {
        top: `${windowSize.height - barWidth}px`,
        width: "100%",
    }

    const baseImageStyle: React.CSSProperties = {
        opacity: 1,  // The actual bar colours have lower opacity
    }

    const imageStyleVertical: React.CSSProperties = {
        width: "auto",
        height: `${barLength}px`,
    }

    const imageStyleHorizontal: React.CSSProperties = {
        width: "500px",
        height: "auto",
    }

    let style: React.CSSProperties
    let imageStyle: React.CSSProperties

    if (isMobile) {
        imageStyle = {
            ...imageStyleVertical,
            ...baseImageStyle,
        }
        style = {
            ...styleVertical,
            ...baseStyle,
        }
    } else {
        imageStyle = {
            ...imageStyleHorizontal,
            ...baseImageStyle,
        }
        style = {
            ...styleHorizontal,
            ...baseStyle,
        }
    }


    return (
        <div style={style}>
            <img src={imageName} alt="Color bar" style={imageStyle} />
        </div>
    );
}

export default memo(ColorBars)