import React from 'react';
import MyMarker from './MyMarker.tsx';
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../reducers/store.ts';
import DirectionArrowSimple from './DirectionArrowSimple.tsx';

function MyMarkers() {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const comparisonMode = useTypedSelector(state => state.data.comparisonMode)

    return (
        <>
            <MyMarker routeNumber={0} comparisonMode={comparisonMode} />
            {comparisonMode &&
                <>
                    <MyMarker routeNumber={1} comparisonMode={comparisonMode} />
                    <DirectionArrowSimple current={true} />
                    <DirectionArrowSimple current={false} />
                </>
            }
        </>
    )
}

export default MyMarkers