import React, { memo } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useDispatch } from 'react-redux'
import { showSlides } from '../reducers/dataSlice.ts'
import { IconEye } from '@tabler/icons-react';
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../reducers/store.ts';
import { usePreventPullToRefreshButton } from './usePreventPullToRefresh.ts';

function ShowSlides({ buttonWidth }) {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const slidesVisible = useTypedSelector(state => state.data.slidesVisible)

    const dispatch = useDispatch()

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();

        dispatch(showSlides())
    }

    const allSlidesVisible = slidesVisible.every(value => value === true);

    const buttonRef = usePreventPullToRefreshButton()

    return (
        (allSlidesVisible ? null :
            <Tooltip label={"Show all slides"}>
                <ActionIcon
                    style={{ borderColor: 'black' }}
                    className={'buttonTransparent'}
                    onClick={handleClick}
                    size={buttonWidth}
                    ref={buttonRef}
                >
                    <IconEye style={{ width: '70%', height: '70%' }} stroke={1.5} color='black' />
                </ActionIcon>
            </Tooltip>
        )
    );
}

export default memo(ShowSlides);
