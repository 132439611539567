import React, { } from 'react';
import useFrameRate from './useFrameRate.ts';

const FPS = () => {
    const fps = useFrameRate()

    const fontSize = '10px'

    const stylesLandscape: React.CSSProperties = {
        position: 'fixed',
        left: '0px',
        top: '0px',
        color: '#ffffff',
        opacity: 1,
        margin: 0,
        padding: 0,
        fontSize: fontSize,
        fontWeight: 'bold',
        lineHeight: fontSize,
    }

    return (
        <div style={stylesLandscape}>
            FPS: {fps.toFixed(0)}
        </div>
    );
};

export default FPS;
