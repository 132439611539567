import React, { ReactNode, useEffect } from "react";
import {
  useMantineTheme,
  Group,
  Tooltip,
  Collapse,
  ActionIcon,
  ActionIconProps,
  Flex,
  Modal,
  Title,
  Space,
  Button,
  Stack,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconShare,
  IconBrandX,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconX,
  IconWorldDownload,
  IconCamera,
  IconDownload,
} from "@tabler/icons-react";
// import CdsDataset from "./cds";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { RootState } from "../reducers/store";
import { toPng } from "html-to-image";
import { isMobile } from "react-device-detect";
import { variableOptions } from './constants.ts'

interface ShareActionIconProps extends ActionIconProps {
  icon?: ReactNode;
  size?: number;
  onClick?: () => void;
  tooltip?: string;
}

interface DownloadButtonProps extends ShareActionIconProps {
  url: string;
}

interface DownloadImageNodeProps extends ShareActionIconProps {
  nodeRef;
  variable;
}

interface DownloadCDSButtonProps extends ShareActionIconProps {
  datasets: string[];
}

interface SharePlatformButtonProps extends ShareActionIconProps {
  sharer?: string;
  shareLink: string;
}

const ShareActionIcon = ({
  icon,
  size,
  tooltip,
  ...props
}: ShareActionIconProps) => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const defaultSize = size ? size : mobile ? 35 : 50;

  return (
    <Tooltip label={tooltip}>
      <ActionIcon size={defaultSize} {...props} styles={{
        root: {
          padding: '0px',
          margin: '0px',
          borderRadius: '100px',
        },
      }}>
        {icon}
      </ActionIcon>
    </Tooltip>
  );
};

export const DownloadButton = ({
  url,
  icon = <IconDownload />,
  ...props
}: DownloadButtonProps) => {
  return (
    <a download={url} href={url}>
      <ShareActionIcon icon={icon} {...props} />
    </a>
  );
};

export const DownloadImageButton = ({
  url,
  tooltip = "Download image (PNG)",
  icon = <IconCamera />,
  ...props
}: DownloadButtonProps) => {
  return (
    <DownloadButton
      url={url}
      tooltip="Download image (PNG)"
      icon={<IconCamera />}
      {...props}
    />
  );
};

export const DownloadImageNodeButton = ({
  nodeRef,
  variable,
  ...props
}: DownloadImageNodeProps) => {

  const tooltip = "Download image (PNG)"
  const icon = <IconCamera color={!props.color ? 'black' : undefined} />

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

  const sliceKey = variableOptions.find((element) => element.name === variable)!.sliceKey;
  const locationPosition1 = useTypedSelector((state) => state.data[sliceKey][1].metadata.locationPosition);

  let xy1_str = ''
  if (locationPosition1 !== null) {
    let nsSuffix = 'N'
    if (locationPosition1.lat < 0) {
      nsSuffix = "S"
    }
    let ewSuffix = 'E'
    if (locationPosition1.lng < 0) {
      ewSuffix = "W"
    }
    xy1_str = `_${Math.abs(locationPosition1.lat).toFixed(2)}${nsSuffix}${Math.abs(locationPosition1.lng).toFixed(2)}${ewSuffix}`
  }

  const locationPosition2 = useTypedSelector((state) => state.data[sliceKey][0].metadata.locationPosition);

  let xy2_str = ''
  if (locationPosition2 !== null) {
    let nsSuffix = 'N'
    if (locationPosition2.lat < 0) {
      nsSuffix = "S"
    }
    let ewSuffix = 'E'
    if (locationPosition2.lng < 0) {
      ewSuffix = "W"
    }
    xy2_str = `_${Math.abs(locationPosition2.lat).toFixed(2)}${nsSuffix}${Math.abs(locationPosition2.lng).toFixed(2)}${ewSuffix}`
  }
  // console.log("DEBUG", xy1_str, xy2_str, variable)

  let variableNoSpaces = variable
    .split(' ')                     // Split the string by spaces into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
    .join('');

  const downloadImage = () => {
    if (nodeRef.current === null) {
      return;
    }

    requestAnimationFrame(() => {
      // Clone the existing node
      const clonedNode = nodeRef.current.cloneNode(true);

      // 1. Find and remove unwanted children from the cloned node
      const unwantedChildren = clonedNode.querySelectorAll('.removeFromPng'); // Customize the selector based on what you want to remove
      unwantedChildren.forEach((child) => {
        child.remove();
      });

      const scrollbar = clonedNode.querySelector('div[data-state="visible"][data-orientation="vertical"][data-mantine-scrollbar="true"]');
      scrollbar?.remove();

      const scrollArea = clonedNode.querySelectorAll('.scrollAreaNamedForCloning'); // Customize the selector based on what you want to remove
      scrollArea.forEach((child) => {
        child.style.display = "inline-block";
        child.style.width = "fit-content";
        child.style.height = "fit-content";
      });

      const badges = clonedNode.querySelectorAll('.badgesNamedForCloning'); // Customize the selector based on what you want to remove
      badges.forEach((child) => {
        child.style.boxShadow = "none";
      });

      // Optionally customize the cloned node
      clonedNode.style.backgroundColor = "white"; // Example customization
      // Make the cloned node tightly fit its children
      clonedNode.style.display = "inline-block";
      clonedNode.style.width = "fit-content";
      clonedNode.style.height = "fit-content";



      // Off-screen container to hold the cloned node
      const offScreenContainer = document.createElement("div");
      offScreenContainer.style.position = "absolute";
      offScreenContainer.style.top = "-9999px";
      offScreenContainer.style.left = "-9999px";
      offScreenContainer.appendChild(clonedNode);

      // Append the container to body
      document.body.appendChild(offScreenContainer);

      toPng(clonedNode)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `ERA-explorer_${variableNoSpaces}${xy1_str}${xy2_str}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          document.body.removeChild(offScreenContainer);
        })
        .catch((err) => {
          console.error("Could not generate image", err);
          document.body.removeChild(offScreenContainer);
        });
    });
  };

  return (
    <ShareActionIcon
      tooltip={tooltip}
      icon={icon}
      onClick={downloadImage}
      {...props}
    />
  );
};

export const SharePlatformButton = ({
  sharer,
  shareLink,
  icon,
  ...props
}: SharePlatformButtonProps) => {
  return (
    <a href={[sharer, shareLink].join("")} target="_blank" rel="noreferrer">
      <ShareActionIcon icon={icon} {...props} />
    </a>
  );
};

export const ShareX = ({
  sharer = "https://x.com/share?url=",
  tooltip = "Share to X (Twitter)",
  shareLink,
  icon = <IconBrandX />,
  ...props
}: SharePlatformButtonProps) => {
  const encodedShareLink = encodeURIComponent(shareLink);  // Required for X (formerly Twitter [lol])

  return (
    <SharePlatformButton
      sharer={sharer}
      shareLink={encodedShareLink}
      tooltip={tooltip}
      icon={icon}
      {...props}
    />
  );
};

export const ShareFacebook = ({
  sharer = "https://www.facebook.com/sharer/sharer.php?u=",
  tooltip = "Share to Facebook",
  shareLink,
  icon = <IconBrandFacebook />,
  ...props
}: SharePlatformButtonProps) => {
  const encodedShareLink = encodeURIComponent(shareLink);
  return (
    <SharePlatformButton
      sharer={sharer}
      shareLink={encodedShareLink}
      tooltip={tooltip}
      icon={icon}
      {...props}
    />
  );
};

export const ShareLinkedIn = ({
  sharer = "https://www.linkedin.com/shareArticle/?url=",
  tooltip = "Share to LinkedIn",
  shareLink,
  icon = <IconBrandLinkedin />,
  ...props
}: SharePlatformButtonProps) => {
  const encodedShareLink = encodeURIComponent(shareLink);

  return (
    <SharePlatformButton
      sharer={sharer}
      shareLink={encodedShareLink}
      tooltip={tooltip}
      icon={icon}
      {...props}
    />
  );
};

export const ShareGroup = (props: { size: number, shareLink: string, children: ReactNode }) => {
  const [shareOpened, { close, toggle }] = useDisclosure(false);

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const displayGraphs = useTypedSelector(state => state.graph.graphsVisible)

  useEffect(() => {
    if (!displayGraphs) {
      close()
    }
  }, [displayGraphs, close]);

  // const size = 50
  const gap = 5
  const size = props.size

  const handleShare = async () => {
    if ((navigator.share) && isMobile) {
      try {
        await navigator.share({
          title: 'ERA-explorer',
          text: 'Weather and climate information from ECMWF',
          url: props.shareLink,
        });
        console.log('SHARE: Content shared successfully');
      } catch (error) {
        console.error('SHARE: Error sharing:', error);
      }
      console.log('SHARE:', props.shareLink)
    } else {
      console.log('SHARE: Web Share API is not supported in your browser.', navigator.share, isMobile);
      console.log('SHARE:', props.shareLink)
      // Open the share group instead
      toggle()
    }
  };


  return (
    <>
      <ShareActionIcon
        size={size}
        tooltip={shareOpened ? "Close" : "Share"}
        onClick={handleShare}
        icon={shareOpened ? <IconX color={"black"} /> : <IconShare color={"black"} />}
        className={'buttonTransparent'}
        // color={shareOpened ? "#941333" : "black"}
        style={{ borderColor: "black" }}
      />
      <div style={{ position: 'absolute', top: "0%", right: `${size + gap}px` }}>
        <Collapse in={shareOpened}>
          {/* This visibility thing is because the tooltips were showing after you'd shown and hidden the group the first time*/}
          <Flex justify="right" gap={gap}>
            {props.children}
          </Flex>
        </Collapse>
      </div>
    </>
    // </Flex>
  );
};

// For posterity
// return (
//   <Flex direction="row" justify="right" gap="5px" style={{ position: "relative", float: "right", marginLeft: "10px", marginRight: "10px" }}>
//     <ShareActionIcon
//       tooltip={shareOpened ? "Close" : "Share"}
//       onClick={toggle}
//       icon={shareOpened ? <IconX /> : <IconShare />}
//       color="#941333"
//     />
//     <div style={{ position: 'absolute', top: "0%", right: "0"}}>
//     <Collapse in={shareOpened}>
//       {/* This visibility thing is because the tooltips were showing after you'd shown and hidden the group the first time*/}
//       {/* <div style={{ position: 'absolute', top: "100%", visibility: shareOpened ? "visible" : "hidden" }}> */}
//         <Flex justify="right" gap="5px">
//           {props.children}
//         </Flex>
//       {/* </div> */}
//     </Collapse>
//     </div>
//   </Flex>
// );

// export const DownloadCDSButton = ({
//   datasets,
//   tooltip = "Download data from the CDS",
//   ...props
// }: DownloadCDSButtonProps) => {
//   const [opened, { open, close }] = useDisclosure(false);

//   const cdsDatasets = datasets.map((dataset) => (
//     <CdsDataset dataset={dataset} key={dataset} />
//   ));

//   return (
//     <>
//       <Modal
//         opened={opened}
//         onClose={close}
//         title={
//           <Title order={3}>Download data from the Climate Data Store</Title>
//         }
//         centered
//         size="62rem"
//       >
//         The following datasets from the C3S Climate Data Store (CDS) were used
//         in the creation of the global temperature trend monitor. For more
//         information on how this data was accessed and processed, see the{" "}
//         <a href="/?tab=source-code">source code</a> tab.
//         {cdsDatasets}
//         <Space h="md" />
//         Additional data not available in the Climate Data Store:
//         <Space h="md" />
//         Climate Projections from the{" "}
//         <a
//           href="https://apps.ipcc.ch/report/sr15/fig1/index.html"
//           target="_blank"
//         >
//           IPCC special report on global warming of 1.5°C
//         </a>
//         &nbsp;&nbsp;&nbsp;&nbsp;
//         <Button
//           color="c3s-red"
//           onClick={() => window.open("ipcc-projections-sr15.csv", "_blank")}
//           rightSection={<IconDownload size={16} />}
//         >
//           Download CSV
//         </Button>
//       </Modal>
//       <ShareActionIcon
//         tooltip={tooltip}
//         onClick={open}
//         icon={<IconWorldDownload />}
//         {...props}
//       />
//     </>
//   );
// };
