function labelFromAdminLevels(admin) {
    // Extract the levels and their corresponding values
    const levels = Object.keys(admin).filter(key => key.startsWith('level')).map(key => ({
        level: parseInt(key.replace('level', '')),
        value: admin[key]
    }));

    // Sort the levels in descending order
    levels.sort((a, b) => b.level - a.level);

    console.log('labelFromAdminLevels', levels)
    if (levels.length > 0) {
        if (levels[levels.length - 1].level <= 2) {
            throw new Error('Got country as lowest level, returning lat/long instead');
        }
    }
    

    // Check if we have at least two levels
    if (levels.length === 1) {
        return levels[0].value;
    } else if (levels.length === 0) {
        return "Unknown"
    }

    // Get the highest and second highest levels
    const highestLevel = levels[0].value;
    const secondHighestLevel = levels[1].value;

    // Return the concatenated string
    return `${highestLevel}, ${secondHighestLevel}`;
}


async function fetchPlaceName(query: string, defaultPlaceName: string, signal: AbortSignal) {
    const server = "https://nominatim.openstreetmap.org/"
    const apiUrl = server + query

    try {
        // Make the API call using fetch with an abort signal
        const response = await fetch(apiUrl, {
            method: "GET",
            mode: "cors",
            headers: {
                'Content-Type': 'text/plain',
                'User-Agent': 'ERA-explorer BETA',  // Replace with your app name and version
                'Referer': 'https://cds.climate.copernicus.eu/apps/c3s/app-era5-explorer', // Replace with your actual domain
            },
            signal
        });

        console.log('fetchPlaceNames', apiUrl)
        console.log('fetchPlaceNames response', response)

        // Check if the response is ok (status is in the range 200-299)
        if (!response.ok) {
            throw new Error('fetchPlaceNames Network response was not ok');
        }

        // Convert the response to JSON
        const data = await response.json();

        let result = defaultPlaceName
        try {
            const adminLevels = data.features[0].properties.geocoding.admin;
            result = labelFromAdminLevels(adminLevels)
        } catch (error2) {
            console.log("Error with geocoding (likely ocean point): " + error2)
        }

        console.log('fetchPlaceNames result', result)

        // Return the JSON data
        return result;
    } catch (error) {
        // Handle abort errors differently from other errors
        if (error.name === 'AbortError') {
            console.log('fetchPlaceNames Fetch aborted');
        } else {
            console.error('fetchPlaceNames Error fetching data:', error);
        }
        throw error;
    }
}

async function fetchPlaceList(apiUrl: string, signal: AbortSignal) {
    try {
        // Make the API call using fetch with an abort signal
        const response = await fetch(apiUrl, {
            method: "GET",
            mode: "cors",
            headers: {
                'Content-Type': 'text/plain',
                'User-Agent': 'ERA-explorer BETA',  // Replace with your app name and version
                'Referer': 'https://cds.climate.copernicus.eu/apps/c3s/app-era5-explorer', // Replace with your actual domain
            },
            signal
        });

        // Check if the response is ok (status is in the range 200-299)
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Convert the response to JSON
        const data = await response.json();

        // Only look for low level features (high admin numbers)...
        let namesAndLocations = data.features.filter(feature => {
            const admin = feature.properties.geocoding.admin;
            // return admin && (admin.level6 || admin.level7 || admin.level8);
            return admin && (admin.level4 || admin.level5 || admin.level6 || admin.level7 || admin.level8);
        }).reduce((acc, feature) => {
            // const label = feature.properties.geocoding.label;
            const label = labelFromAdminLevels(feature.properties.geocoding.admin);
            const position = [feature.geometry.coordinates];

            // Check if label is already in the Set
            if (!acc.seenLabels.has(label) && (label !== 'Unknown')) {
                acc.seenLabels.add(label); // Add label to Set
                acc.result.push([label, position]); // Push to result array
            }
            return acc;
        }, { result: [], seenLabels: new Set() }).result;

        const maxOptionsToDisplay = 4;
        namesAndLocations = namesAndLocations.slice(0, maxOptionsToDisplay)  // Now truncate to some number

        // Return the JSON data
        return namesAndLocations;
    } catch (error) {
        // Handle abort errors differently from other errors
        if (error.name === 'AbortError') {
            console.log('Fetch aborted');
        } else {
            console.error('Error fetching data:', error);
        }
        throw error;
    }
}

export { fetchPlaceList, fetchPlaceName }