import React, { useState, useMemo, useEffect, useRef, memo, useCallback } from 'react';
import { Paper, Stack, ScrollArea, Badge, Group, Tooltip } from '@mantine/core';
// import Plot from "react-plotly.js";
import scrollBarClass from './ScrollBar.module.css';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import MakeGraph from './MakeGraph.tsx';
import GraphLoading from './GraphLoading.tsx';
import { setAttentionReceived } from '../reducers/graphSlice.ts'
import { RootState } from '../reducers/store.ts';
import { stripeColorsGrey, dailyPolarColors, frostColor2, summerColor2, tropicalColor2, variableOptions } from './constants.ts';
import ComplexBadge from './ComplexBadge.tsx';
import { ShareGroup, ShareFacebook, ShareX, ShareLinkedIn, DownloadImageNodeButton } from './share.tsx';
import { isMobile } from 'react-device-detect';
import AccordionGraphs from './AccordionGraphs.tsx';
import HideSlide from './HideSlide.tsx';
import ShowSlides from './ShowSlides.tsx';
import { usePreventPullToRefreshDiv } from './usePreventPullToRefresh.ts';
// import { useMantineTheme } from '@mantine/core';

function makeShareLink(index) {
    // Get the URLSearchParams object from the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // Get the values of 'lat' and 'lng', if they exist
    const lat = urlParams.get('lat');
    const lng = urlParams.get('lng');

    // Create a new URL, preserving only 'lat' and 'lng' parameters
    let newParams = '';
    if (lat) newParams += `lat=${lat}`;
    if (lng) newParams += `${newParams ? '&' : ''}lng=${lng}`;  // Add '&' only if 'lat' exists

    // Update the URL
    const newUrl = `${window.location.origin}${window.location.pathname}${newParams ? '?' + newParams : ''}${newParams ? '&' : '?'}plot=${index}`;

    return newUrl
}

function arraysAreEqual(arr1: boolean[], arr2: boolean[]) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

function getStripesHex(fraction, colors, scatterMax) {
    const margin = scatterMax * 2
    const fractionScaled = fraction * (1 - margin) + margin / 2  // Scale it a bit so we don't saturate at the ends too much

    const randomScatter = scatterMax * Math.sin((Math.random() - 0.5) * Math.PI)

    const indexRounded = Math.round((fractionScaled + randomScatter) * colors.length)
    const indexClamped = Math.min(Math.max(0, indexRounded), colors.length - 1)

    return colors[indexClamped]
}

function makeStripesBadgeGradient(nCols, colors, scatter) {
    const opacityHex = '88'
    let stripesString = `linear-gradient(90deg`
    const step = 100 / nCols
    for (let i = 0; i < nCols; i++) {
        const colorHex = getStripesHex(i / nCols, colors, scatter) + opacityHex
        const percent1 = i * step
        const percent2 = percent1 + step
        stripesString += `, ${colorHex} ${percent1}%, ${colorHex} ${percent2}%`
    }
    stripesString += `) `
    return stripesString
}

function makeThresholdGradient2() {
    const color1 = 'rgb(40, 40, 40)'
    const color2 = 'rgb(80, 80, 80)'
    const color3 = 'rgb(40, 40, 40)'
    const gradient = `linear-gradient(90deg, ${color1} 0, ${color1} 28%, ${color2} 38%, ${color2} 62%, ${color3} 72%, ${color3} 100%)`
    return gradient
}

const borderColorUnclickable = 'rgb(210, 210, 210)'
// const borderWidth = 2
const textColorUnclickable = 'black'
// const backgroundColorUnclickable = 'rgb(240, 240, 240)'


function ScienceBox({ width, height, variable, landscape, buttonIndex, mutuallyExclusive = false }) {
    useEffect(() => {
        console.log("Rendered ScienceBox")
    })

    // const theme = useMantineTheme();

    const nodeRef = useRef(null)
    const scrollAreaRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const scrollAreaElement = scrollAreaRef.current;

        if (scrollAreaElement) {
            const stopPropagation = (e) => {
                // If the ScrollArea can scroll, prevent the wheel event from reaching Embla
                const { scrollTop, scrollHeight, clientHeight } = scrollAreaElement;
                const canScroll = scrollHeight > clientHeight;

                if (canScroll) {
                    // Detect scrolling direction
                    const isScrollingUp = e.deltaY < 0;
                    const isScrollingDown = e.deltaY > 0;

                    // Prevent scrolling when at the top or bottom
                    const isAtTop = scrollTop === 0;
                    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

                    console.log("SCROLL", isScrollingUp, isScrollingDown, isAtTop, isAtBottom)

                    if (
                        (isScrollingUp && isAtTop) ||
                        (isScrollingDown && isAtBottom)
                    ) {
                        // Allow Embla to handle the scroll event when at the boundary
                        return;
                    }

                    // Stop propagation if ScrollArea is still scrollable
                    e.stopPropagation();
                }
            };

            // Attach event listener to prevent propagation of wheel event
            scrollAreaElement.addEventListener('wheel', stopPropagation);

            // Cleanup event listener on component unmount
            return () => {
                scrollAreaElement.removeEventListener('wheel', stopPropagation);
            };
        }
    }, []);

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    console.log("DEBUG", variable, variableOptions.find((element) => element.name === variable))
    const sliceKey = variableOptions.find((element) => element.name === variable)!.sliceKey;
    const gotCurrentData = useTypedSelector((state) => state.data[sliceKey][0].metadata.gotData);
    let gotPreviousData = useTypedSelector((state) => state.data[sliceKey][1].metadata.gotData);

    const climPeriod = useTypedSelector((state) => state.data[sliceKey][0].metadata.clim_period);

    const comparisonMode = useTypedSelector((state) => state.data.comparisonMode)
    if (gotCurrentData) {
        gotPreviousData = gotPreviousData && comparisonMode
    }

    const graphHeight = height * 0.45;
    const graphWidth = width * 0.9;
    // const scrollHeight = landscape ? (height * 0.44) : (height * 0.42);
    const scrollHeight = height - graphHeight - 80

    const [showPrevCurr, setShowPrevCurr] = useState<boolean[]>([false, true]);

    const [showABC, setShowABC] = useState<boolean[]>([true, true, true])
    const [ready, setReady] = useState(false);

    // console.log("DEBUG", gotCurrentData, gotPreviousData, (gotCurrentData && gotPreviousData));
    if (ready !== (gotCurrentData && gotPreviousData)) {
        // console.log("Rendered ScienceBox - ready", ready);
        setReady((gotCurrentData && gotPreviousData))
    }

    useEffect(() => {
        console.log("MakeGraph - ready", ready)
    }, [ready])

    const previousGotCurrentDataRef = useRef<boolean | null>(null);

    useEffect(() => {
        if (previousGotCurrentDataRef.current === false && gotCurrentData === true) {
            console.log("gotCurrentData just changed to true from false (new data just arrived)");
            if (mutuallyExclusive) {
                setShowPrevCurr([false, true])
            } else {
                setShowPrevCurr([true, true])
            }
        } else if (previousGotCurrentDataRef.current === true && gotCurrentData === false) {
            console.log("gotCurrentData just changed to false from true (user clicked and latest data was made previous)");
            // Add any logic you want to run when gotCurrentData changes from false to true
            setShowPrevCurr([true, false])
        }
        // Update the ref to the current value for the next render
        previousGotCurrentDataRef.current = gotCurrentData;
    }, [gotCurrentData, mutuallyExclusive]);

    const dispatch = useDispatch()

    // const variableSplit = variable.split(" ")
    const shadowOffset = 3


    // let titleText = variableSplit[0];
    const units = variableOptions.find((element) => element.name === variable)!.units;
    const aggregation = variableOptions.find((element) => element.name === variable)!.aggregation;
    const statistic = variableOptions.find((element) => element.name === variable)!.statistic;
    const titleText = variableOptions.find((element) => element.name === variable)!.title;
    // let period: string | null = climPeriod
    // let color1 = 'UNSET'
    // let color2: string | null = null
    let backgroundGradient: string = ""
    let backgroundGradient2: string = ""
    const fontSizeH = landscape ? (0.025 * window.innerHeight) : (0.02 * window.innerHeight)
    const fontSizeW = landscape ? (0.045 * window.innerWidth) : (0.045 * window.innerWidth)
    const fontSizeBoxW = landscape ? (0.055 * width) : (0.055 * width)
    const fontSize = `${Math.max(Math.min(fontSizeH, fontSizeW, fontSizeBoxW), 16)}px`
    const fontSizeUnits = `${Math.max(0.6 * Math.min(fontSizeH, fontSizeW), 14)}px`
    console.log("fontSize", landscape, fontSizeBoxW, fontSizeH, fontSizeW, fontSize)
    // let extraBadges = false

    backgroundGradient = useMemo(() => {
        switch (variable) {
            case "Temperature daily climatology":
                return makeStripesBadgeGradient(50, dailyPolarColors, 0);
            case "Frost days etc":
                return makeThresholdGradient2();
            case "Temperature warming stripes":
                return makeStripesBadgeGradient(50, stripeColorsGrey, 0.1);
            default:
                return "";
        }
    }, [variable]);

    backgroundGradient2 = useMemo(() => {
        switch (variable) {
            case "Frost days etc":
                return makeThresholdGradient2();
            default:
                return "";
        }
    }, [variable]);

    // iconX = useMemo(() => variable === "Frost days etc" ? <IconX stroke={5} style={{ width: rem(15), height: rem(15) }} /> : null, [variable]);
    // iconPlus = useMemo(() => variable === "Frost days etc" ? <IconCircleFilled stroke={5} style={{ width: rem(13), height: rem(13) }} /> : null, [variable]);

    const color1 = variableOptions.find((element) => element.name === variable)!.color1;
    const color2 = variableOptions.find((element) => element.name === variable)!.color2;
    const showPeriod = variableOptions.find((element) => element.name === variable)!.statistic !== "timeseries";
    const extraBadges = (variable === "Frost days etc") ? true : false

    const color1Mod = color1
    const color2Mod = ["Rainfall", "Temperature"].includes(
        variableOptions.find((element) => element.name === variable)!.title
    ) ? (color2 + 'c3') : color2;

    if (backgroundGradient2 === "") {
        backgroundGradient2 = backgroundGradient
    }

    const heightWithoutShadow = landscape ? height : (height - shadowOffset)

    const handleClick1 = useCallback(() => {
        console.log("Clicked 1")
        if (!ready) {
            return
        }

        if (showPrevCurr[0]) {
            // If we're about to change the first bars to hidden, then make sure we're showing the second
            // setShowSecond(true)
            setShowPrevCurr([!showPrevCurr[0], true])
        } else if (mutuallyExclusive) {
            // We're about to REVEAL the first bars. As we're exclusive, hide the second ones
            // setShowSecond(false)
            setShowPrevCurr([!showPrevCurr[0], false])
        } else {
            // setShowFirst(!showFirst)
            setShowPrevCurr([!showPrevCurr[0], showPrevCurr[1]])
        }

        dispatch(setAttentionReceived())
    }, [ready, showPrevCurr, mutuallyExclusive, dispatch]);

    const handleClick2 = useCallback(() => {
        console.log("Clicked 2")
        if (!ready) {
            return
        }
        if (showPrevCurr[1]) {
            // setShowFirst(true)
            setShowPrevCurr([true, !showPrevCurr[1]])
        } else if (mutuallyExclusive) {
            // We're about to REVEAL the second bars. As we're exclusive, hide the first ones
            // setShowFirst(false)
            setShowPrevCurr([false, !showPrevCurr[1]])
        } else {
            // setShowSecond(!showSecond)
            setShowPrevCurr([showPrevCurr[0], !showPrevCurr[1]])
        }

        dispatch(setAttentionReceived())
    }, [ready, showPrevCurr, mutuallyExclusive, dispatch]);

    let textColorSelected = 'white'
    let textColorUnselected = 'black'
    let blankBackgroundColor = 'rgb(200, 200, 200)'
    if ((backgroundGradient !== "") && (variable !== "Frost days etc")) {
        textColorSelected = 'black'
        // textColorUnselected = 'white'
        // blankBackgroundColor = 'rgb(200, 200, 200)'
    }

    let textColorSelectedPrev = textColorSelected
    if (variableOptions.find((element) => element.name === variable)!.title === "Rainfall") {
        textColorSelectedPrev = 'black'
    } else if (variableOptions.find((element) => element.name === variable)!.title === "Temperature") {
        textColorSelectedPrev = 'black'
    }

    let textColorSelectedCurrent = textColorSelected
    if (variableOptions.find((element) => element.name === variable)!.title === "Wind") {
        textColorSelectedPrev = 'black'
        textColorSelectedCurrent = 'black'
    }

    const basicBadgeStyles = {
        root: {
            borderWidth: 0,
            borderColor: borderColorUnclickable,
            backgroundColor: '#ccc',
            color: textColorUnclickable,
        }
    }

    const colorsMemoized = useMemo(() => [color1Mod, color2Mod], [color1Mod, color2Mod]);

    const smallButtonWidth = isMobile ? 45 : 35

    const dragToCloseRef1 = usePreventPullToRefreshDiv();
    const dragToCloseRef2 = usePreventPullToRefreshDiv();
    const dragToCloseRef3 = usePreventPullToRefreshDiv();
    const dragToCloseRef4 = usePreventPullToRefreshDiv();

    const previousBadge = (
        <ComplexBadge
            label="Loading..."
            onClick={handleClick1}
            showBadge={showPrevCurr[0]}
            color={color1}
            textColorSelected={textColorSelectedPrev}
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            ready={ready}
            landscape={landscape}
            backgroundGradient={showPrevCurr[0] ? backgroundGradient : ""}
            gradientButton={(backgroundGradient !== "")}
            animationDelay={0}
            // rightSection={iconX}
            variable={variable}
            current={false}
            comparisonMode={comparisonMode}
            tooltipLabel={comparisonMode ? 'Toggle location' : 'Location'}
        />
    );

    const currentBadge = (
        <ComplexBadge
            label="Loading..."
            onClick={handleClick2}
            showBadge={showPrevCurr[1]}
            color={color2}
            textColorSelected={textColorSelectedCurrent}
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            ready={ready}
            landscape={landscape}
            backgroundGradient={showPrevCurr[1] ? backgroundGradient2 : ""}
            gradientButton={(backgroundGradient !== "")}
            animationDelay={0.5}
            // rightSection={iconPlus}
            variable={variable}
            current={true}
            comparisonMode={comparisonMode}
            tooltipLabel={comparisonMode ? 'Toggle location' : 'Location'}
        />
    );

    const initialBadge = (
        <ComplexBadge
            label="Select on map"
            onClick={() => { }}
            showBadge={true}
            color="white"
            textColorSelected="black"
            textColorUnselected="black"
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={1}
            ready={ready}
            landscape={landscape}
            ignoreAttention={true}
            tooltipLabel={'Select a location to compare'}
        />
    );

    const handleFrostClick = useCallback(() => {
        console.log("Clicked frost")

        if (arraysAreEqual(showABC, [true, false, false])) {
            // setShowB(true)
            setShowABC([false, true, false])
        } else {
            // setShowA(!showA)
            setShowABC([!showABC[0], showABC[1], showABC[2]])
        }

        dispatch(setAttentionReceived())
    }, [showABC, dispatch]);

    const handleSummerClick = useCallback(() => {
        console.log("Clicked summer")

        if (arraysAreEqual(showABC, [false, true, false])) {
            // setShowC(true)
            setShowABC([false, false, true])
        } else {
            // setShowB(!showB)
            setShowABC([showABC[0], !showABC[1], showABC[2]])
        }

        dispatch(setAttentionReceived())
    }, [showABC, dispatch]);

    const handleTropicalClick = useCallback(() => {
        console.log("Clicked tropical")

        if (arraysAreEqual(showABC, [false, false, true])) {
            // setShowA(true)
            setShowABC([true, false, false])
        } else {
            // setShowC(!showC)
            setShowABC([showABC[0], showABC[1], !showABC[2]])
        }

        dispatch(setAttentionReceived())
    }, [showABC, dispatch]);

    const showBadgeA = extraBadges ? (
        <ComplexBadge
            label="Frost days"
            onClick={handleFrostClick}
            showBadge={showABC[0]}
            color={frostColor2}
            textColorSelected='white'
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={1}
            ready={ready}
            landscape={landscape}
            overrideReady={true}
            tooltipLabel='Frequency of frost days'
        />
    ) : null;

    const showBadgeB = extraBadges ? (
        <ComplexBadge
            label="Summer days"
            onClick={handleSummerClick}
            showBadge={showABC[1]}
            color={summerColor2}
            textColorSelected="black"
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={0.6}
            ready={ready}
            landscape={landscape}
            overrideReady={true}
            tooltipLabel='Frequency of summer days'
        />
    ) : null;

    const showBadgeC = extraBadges ? (
        <ComplexBadge
            label="Tropical nights"
            onClick={handleTropicalClick}
            showBadge={showABC[2]}
            color={tropicalColor2}
            textColorSelected='white'
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={0.3}
            ready={ready}
            landscape={landscape}
            overrideReady={true}
            tooltipLabel='Frequency of tropical nights'
        />
    ) : null;

    // const shareButtonSize = isMobile ? 55 : 55

    const somethingToShow = ((gotPreviousData) || (gotCurrentData)) ? true : false
    const loadingZIndex = somethingToShow ? 0 : 10
    const loadingVisibility = somethingToShow ? "hidden" : "visible"

    const shareLink = makeShareLink(buttonIndex)

    const borderColor = variableOptions.find((element) => element.name === variable)!.color2;

    return (
        <Paper
            withBorder
            className='cardClass'
            styles={{
                root: {
                    borderRadius: '6px',
                    width: `${width}px`,
                    height: `${heightWithoutShadow}px`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderColor: `${borderColor}`,
                    borderWidth: 1,
                    // boxShadow: `${shadowOffset}px ${shadowOffset}px #00000022`,
                    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.3)',
                    backgroundColor: '#eee',
                },
            }}
        >

            <Stack
                h={height}
                align='center'
                justify='flex-start'
                gap={0}
                styles={{
                    root: {
                        padding: '20px',
                    }
                }}
            >
                <div
                    style={{
                        padding: '30px 10px 0px 10px',
                        display: "inline-block"
                    }}  // top, right, bottom, left
                    ref={nodeRef}
                >
                    <div
                        style={{
                            position: 'relative',
                            width: graphWidth,
                            height: graphHeight
                        }}
                        ref={dragToCloseRef1}
                    >
                        <div style={{ zIndex: 5, position: 'absolute' }}>
                            {somethingToShow &&
                                <MakeGraph
                                    width={graphWidth}
                                    height={graphHeight}
                                    variable={variable}
                                    colors={colorsMemoized}
                                    showLocations={showPrevCurr}
                                    showLines={showABC}
                                    landscape={landscape}
                                />
                            }
                        </div>
                        <div style={{ zIndex: loadingZIndex, position: 'absolute', visibility: loadingVisibility }}>
                            <GraphLoading
                                width={graphWidth}
                                height={graphHeight}
                            />
                        </div>
                    </div>

                    <ScrollArea
                        h={scrollHeight}
                        type="hover"
                        scrollbars="y"
                        classNames={scrollBarClass}
                        className='scrollAreaNamedForCloning'
                        styles={{
                            root: {
                                justifyItems: 'left',
                                textAlign: "left"
                            }
                        }}
                        offsetScrollbars={true}
                        viewportRef={scrollAreaRef}
                    >

                        <div
                            style={{
                                justifyItems: 'left',
                                textAlign: 'left',
                                width: '100%'
                            }}
                            // ref={dragToCloseRef2}
                        >
                            <p className='CardTitle'>
                                <span className='CardTitleMain' style={{ fontSize: fontSize }}>{titleText} </span>
                                <span className='CardTitleUnits' style={{ fontSize: fontSizeUnits }}>[{units}]</span>
                            </p>
                            <p className='CardPara'>
                                <Tooltip label={"How the data was aggregated"}>
                                    <Badge component='span' styles={basicBadgeStyles} >
                                        {aggregation}
                                    </Badge>
                                </Tooltip>

                                <span> </span>
                                <Tooltip label={"The type of statistic"}>
                                    <Badge component='span' styles={basicBadgeStyles} >
                                        {statistic}
                                    </Badge>
                                </Tooltip>

                                {(showPeriod && (climPeriod !== null)) && (
                                    <>
                                        <span> </span>
                                        <Tooltip label={"The reference period"}>

                                            <Badge component='span' styles={basicBadgeStyles} >
                                                {climPeriod}
                                            </Badge>
                                        </Tooltip>
                                    </>
                                )}

                                <br></br>

                                {(extraBadges && (gotCurrentData || gotPreviousData)) && (
                                    <>
                                        {showBadgeA}
                                        <span> </span>
                                        {showBadgeB}
                                        <span> </span>
                                        {showBadgeC}
                                        <br></br>
                                    </>
                                )}

                                {(gotPreviousData) && (
                                    <>
                                        {previousBadge}
                                        <span> </span>
                                        {currentBadge}
                                    </>
                                )}

                                {((!gotPreviousData) && (gotCurrentData)) && (
                                    <>
                                        {currentBadge}
                                        <span> </span>
                                        {(comparisonMode) && (initialBadge)}
                                    </>
                                )}
                            </p>
                        </div>

                        <div className='removeFromPng'>
                            <AccordionGraphs variable={variable} period={climPeriod} />
                        </div>

                    </ScrollArea>
                </div>
            </Stack>

            <>
                {isMobile ?
                    <Group
                        className='hover-div-mobile'
                        style={{
                            zIndex: 1000,
                            position: 'absolute',
                            top: 0,
                            left: (!landscape ? 0 : 0),
                            margin: 7,
                        }}
                        gap={5}
                        ref={dragToCloseRef3}
                    >
                        <DownloadImageNodeButton size={smallButtonWidth} nodeRef={nodeRef} variable={variable} style={{ borderColor: 'black' }}
                            className={'buttonTransparent'} />
                    </Group>
                    :
                    <Group
                        className='hover-div'
                        style={{
                            zIndex: 1000,
                            position: 'absolute',
                            top: 0,
                            left: (!landscape ? 0 : 0),
                            margin: 7,
                        }}
                        gap={5}
                        ref={dragToCloseRef3}
                    >
                        <HideSlide buttonWidth={smallButtonWidth} buttonIndex={buttonIndex} />
                        <ShowSlides buttonWidth={smallButtonWidth} />
                    </Group>
                }

                <Group
                    className={isMobile ? 'hover-div-mobile' : 'hover-div'}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        top: 0,
                        right: (!landscape ? 7 : 0),
                        margin: 7,
                    }}
                    gap={5}
                    ref={dragToCloseRef4}
                >
                    <ShareGroup size={smallButtonWidth} shareLink={shareLink}>
                        <DownloadImageNodeButton size={smallButtonWidth} nodeRef={nodeRef} variable={variable} color="#941333" />
                        <ShareFacebook size={smallButtonWidth} shareLink={shareLink} color="#941333" />
                        <ShareX size={smallButtonWidth} shareLink={shareLink} color="#941333" />
                        <ShareLinkedIn size={smallButtonWidth} shareLink={shareLink} color="#941333" />
                    </ShareGroup>
                </Group>
            </>
        </Paper>
    )
};

export default memo(ScienceBox)