import { theme } from '../theme.tsx';

export const stripeColorsGrey = [
    '#08306b', '#08519c', '#2171b5', '#4292c6',
    '#6baed6', '#9ecae1', '#c6dbef', '#deebf7', '#dddddd',
    '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a',
    '#ef3b2c', '#cb181d', '#a50f15', '#67000d',
];

export const stripeColorsWhite = [
    '#08306b', '#08519c', '#2171b5', '#4292c6',
    '#6baed6', '#9ecae1', '#c6dbef', '#deebf7', '#ffffff',
    '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a',
    '#ef3b2c', '#cb181d', '#a50f15', '#67000d',
];

// https://leonardocolor.io/scales.html#
// Must be odd!
// export const dailyPolarColors = [
//     "#4f4545", "#584747", "#624949", "#6c4a4a", "#764c4b", "#7f4e4b",
//     "#884f4c", "#91504c", "#9a514c", "#a3524c", "#ac534a", "#b55349",
//     "#be5447", "#c65445", "#cf5543", "#d75540", "#df563d", "#e7563a",
//     "#ef5736", "#f75732", "#ff582e"
// ];

export const dailyPolarColors = [
    "#0055ff", "#4376ce", "#859bb4", "#b1bab8", "#d3d7b2", "#f2eaa3", "#fff29e", "#fee996", "#fed383", "#ffb369", "#ff8949", "#ff4e21", "#ff0000"
]

export const months_short = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const cumulDays = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366];

export const c3sred = theme.colors.c3sRed[9]
export const c3syellow = theme.colors.c3sYellow[9]
export const c3sblue = theme.colors.c3sBlue[9]
export const c3sgrey = theme.colors.c3sGrey[9]

// export const c3sredAlt = '#d97c93'
// export const c3syellowAlt = '#ffd84d'
// export const c3syellowAlt2 = c3sred
// export const c3sblueAlt = '#8aa2ed'

export const c3sredAlt = theme.colors.c3sRed[4]
export const c3syellowAlt = theme.colors.c3sYellow[7]
export const c3syellowAlt2 = theme.colors.c3sYellow[4]
export const c3sblueAlt = theme.colors.c3sBlue[4]


export const frostColor = c3sblueAlt
export const summerColor = c3syellowAlt
export const tropicalColor = c3sredAlt
export const frostColor2 = c3sblue
export const summerColor2 = c3syellow
export const tropicalColor2 = c3sred
export const temperatureColor = c3sredAlt
export const precipColor = c3sblueAlt
export const temperatureColor2 = c3sred
export const precipColor2 = c3sblue

export const windColors = [c3syellowAlt2, c3syellow, c3syellowAlt2, c3syellow]
export const windColors2 = windColors

export const variableOptions = [
    {
        name: "Precip monthly climatology",
        title: "Rainfall",
        units: "mm/month",
        route: "precip-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "precipMonthly",
        color1: precipColor,
        color2: precipColor2,
    },
    {
        name: "Temperature monthly climatology",
        title: "Temperature",
        units: "°C",
        route: "temperature-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "tempMonthly",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Frost days etc",
        title: "Temperature thresholds",
        units: "% days",
        route: "temperature-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "tempMonthly",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Precip annual timeseries",
        title: "Rainfall",
        units: "mm/year",
        route: "precip-annual-timeseries",
        mutuallyExclusive: false,
        aggregation: "annual",
        statistic: "timeseries",
        sliceKey: "precipAnn",
        color1: precipColor,
        color2: precipColor2,
    },
    {
        name: "Temperature annual timeseries",
        title: "Temperature",
        units: "°C",
        route: "temperature-annual-timeseries",
        mutuallyExclusive: false,
        aggregation: "annual",
        statistic: "timeseries",
        sliceKey: "tempAnn",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Temperature warming stripes",
        title: "Warming stripes",
        units: "°C",
        route: "temperature-annual-timeseries",
        mutuallyExclusive: false,
        aggregation: "annual",
        statistic: "anomaly",
        sliceKey: "tempAnn",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Wind annual climatology",
        title: "Wind",
        units: "% hours",
        route: "wind-annual-clim",
        mutuallyExclusive: true,
        aggregation: "hourly",
        statistic: "climatology",
        sliceKey: "windAnn",
        color1: windColors[1],
        color2: windColors2[1],
    },
    {
        name: "Precip hourly hist",
        title: "Rainfall",
        units: "% hours",
        route: "precip-hourly-hist",
        mutuallyExclusive: false,
        aggregation: "hourly",
        statistic: "distribution",
        sliceKey: "precipHourly",
        color1: precipColor,
        color2: precipColor2,
    },
    {
        name: "Temperature daily climatology",
        title: "Temperature",
        units: "°C",
        route: "temperature-daily-clim",
        mutuallyExclusive: true,
        aggregation: "daily",
        statistic: "climatology",
        sliceKey: "tempDaily",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
]

export const TOTAL_SLIDES = variableOptions.length
export const MIN_SLIDES_REQD = 3
export const MARGIN = 15
// export const TRANSLUCENT_COLOR = "#75787bcc"
export const TRANSLUCENT_COLOR = "#75787b"
export const DEFAULT_LOCATION = { lat: 50.86, lng: 4.35 }