import { configureStore } from '@reduxjs/toolkit'
import graphReducer from './graphSlice.ts'
// import calendarReducer from './calendarSlice.ts'
import locationReducer from './locationSlice.ts'
import dataReducer from './dataSlice.ts'

import urlSyncMiddleware from '../middleware/urlSyncMiddleware.js';
import { getStateFromUrl } from '../utils/urlState.ts';

// Initialize the state from the URL
const urlState = getStateFromUrl();

const store = configureStore({
  reducer: {
    graph: graphReducer,
    // calendar: calendarReducer,
    location: locationReducer,
    data: dataReducer,
  },
  preloadedState: {
    location: {
      ...locationReducer(undefined, { type: 'INIT' }), // Get the default initial state
      locationPosition: urlState.location.locationPosition, // Override with URL state
    },
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(urlSyncMiddleware),
})

// Define the RootState type
export type RootState = ReturnType<typeof store.getState>;

export default store