import React, { useEffect, memo } from 'react'
import '@mantine/carousel/styles.css';

import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../reducers/store.ts';
import MyCarousel from './MyCarousel.tsx';
import { isMobile } from 'react-device-detect';

function CarouselContainer({ windowSize }) {
  useEffect(() => {
    console.log("Rendered CarouselContainer")
  })

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const displayGraphs = useTypedSelector(state => state.graph.graphsVisible)
  const multipanel = useTypedSelector(state => state.graph.multipanel)

  const slideAspectRatio = 1.7  // Height / width of the card
  let landscape = true
  if ((windowSize.width < windowSize.height) || multipanel) {
    landscape = false
  }

  let slideWidth = window.innerWidth * 0.87
  let slideHeight = slideWidth * slideAspectRatio
  let height = slideHeight

  let myStyle
  let transform

  if (landscape && !multipanel) {
    // TODO: This is duplicated inside the carousel code!
    // If landscape then put scroll on the right
    height = window.innerHeight

    slideHeight = Math.min(window.innerHeight * 0.9, 900)
    slideWidth = slideHeight / slideAspectRatio

    const maxSlideWidth = 450
    if (slideWidth > maxSlideWidth) {
      slideWidth = maxSlideWidth
      slideHeight = slideWidth * slideAspectRatio
    }
    
    transform = `translateX(${slideWidth * 0.98}px)`

    myStyle = {
      right: "0px",
      height: { height },
      minHeight: { height },
      maxHeight: { height },
      top: '0px',
      textAlign: "right",
      display: "flex",
      // zIndex: 10000,
    }

  } else {
    // If portrait (i.e. mobile) then put scroll on the bottom and ensure that the overall height of the slide won't be too large
    const maxSlideHeightRatio = isMobile ? 0.7 : 0.62
    const maxHeight = window.innerHeight * maxSlideHeightRatio
    const slideHeightRatio = slideHeight / maxHeight
    if (slideHeightRatio > 1) {
      slideHeight /= slideHeightRatio
    }
    const minAspectRatio = multipanel ? 1.7 : 1.2
    if ((slideHeight / slideWidth) < minAspectRatio) {
      slideWidth = slideHeight / minAspectRatio
    }
    height = slideHeight

    transform = `translateY(${height}px)`

    myStyle = {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      bottom: `0px`,
      textAlign: "right",
      // zIndex: 10000,
    }
  }

  const containerStyle: React.CSSProperties = {
    ...myStyle,
    position: 'fixed',
    pointerEvents: "none",
    transform: transform,
    transition: "transform 500ms ease",
  }

  const containerStyleHidden: React.CSSProperties = {
    ...myStyle,
    position: 'fixed',
    pointerEvents: "auto",
    transform: `translateX(0)`,
    transition: "transform 500ms ease",
  }

console.log("CAROUSEL CONTAINER", slideWidth, slideHeight, multipanel)

  return (
    <div
      style={!displayGraphs ? containerStyle : containerStyleHidden}
    >
        <MyCarousel windowSize={windowSize} landscape={landscape} slideWidth={slideWidth} slideHeight={slideHeight} multipanel={multipanel}/>
    </div>
  );
}

export default memo(CarouselContainer)