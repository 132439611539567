import React, { useEffect, useRef, useState, memo } from 'react';
import { Combobox, Loader, TextInput, useCombobox, ActionIcon } from '@mantine/core';

import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
// import { updateName } from '../reducers/locationSlice.ts'
// import { useFocusReturn } from '@mantine/hooks';
// import useFetchDemoData from './useFetchDemoData.ts';
import useUpdatePositionAndData from './useUpdatePositionAndData.ts';

import { fetchPlaceList } from './OSM_API.ts'
import { RootState } from '../reducers/store.ts';

import { closeGraphs, openGraphs } from '../reducers/graphSlice.ts';
import { IconLocation, IconLocationFilled, IconMapPinFilled, IconX } from '@tabler/icons-react';
import { theme } from '../theme.tsx';
import { setHaveGeolocated, triggerPanToCurrentLocation } from '../reducers/locationSlice.ts'

import fetchGeolocation from './Geolocation.tsx'
import { useMap } from 'react-leaflet';
// import { useSequenceAbortController } from './SequenceAbortControllerProvider.tsx';

// import fetchZarrData from './fetchZarrData.ts'


// import { labelFromAdminLevels } from './Location.ts'
// import '@mantine/core/styles/Combobox.css';
// import myComboboxStyle from './Combobox.module.css';

// Function to make an external API call and return a JSON object

function LocationSearchBar({ barWidth }) {
  // const returnFocus = useFocusReturn({
  //   opened: false,
  //   shouldReturnFocus: false,
  // });
  const [controller, setController] = useState<AbortController | null>(null);  // AbortController stored in state

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      // returnFocus()
    },
    onDropdownOpen() {
      console.log('onDropdownOpen')
      dispatch(closeGraphs())
    },
  });

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const locationName = useTypedSelector(state => state.location.locationName)
  // const locationPosition = useTypedSelector(state => state.location.locationPosition)
  const routeNumber = useTypedSelector(state => state.data.routeNumber)
  const geolocationToggle = useTypedSelector(state => state.location.geolocationToggle)
  const haveGeolocated = useTypedSelector(state => state.location.haveGeolocated)

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<{ string: number[] }> | null>(null);

  const [value, setValue] = useState<string>(locationName);

  const [empty, setEmpty] = useState(false);
  const abortController = useRef<AbortController>();

  // const [averageTemp, setAverageTemp] = useState(-999);
  // const abortControllerDemo = useRef<AbortController>();

  // We need to useEffect to set the value if we want it set here, otherwise the value is actually only set ON THE FIRST RENDER!
  useEffect(() => {
    setValue(locationName)
    setData(null);
  }, [locationName])

  // const { fetchDemoData } = useFetchDemoData();

  // useEffect(() => {
  //   fetchDemoData(initialPosition.lat, initialPosition.lng);
  // }, [fetchDemoData]);

  const dispatch = useDispatch()

  const { updatePositionAndData } = useUpdatePositionAndData();

  const fetchOptions = (query: string) => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    setLoading(true);

    const queryURL = `https://nominatim.openstreetmap.org/search?q=${query}&format=geocodejson&addressdetails=1`
    console.log("queryURL", queryURL);

    fetchPlaceList(queryURL, abortController.current.signal)
      .then((result) => {
        setData(result);
        setLoading(false);
        setEmpty(result.length === 0);
        abortController.current = undefined;
      })
      .catch(() => { });
  };

  const options = (data || [[]]).map((item, index) => (
    <Combobox.Option value={item[0]} key={index}>
      {item[0]}
    </Combobox.Option>
  ));

  const findNumbersArray = (searchString: string): number[] | null => {
    let newPosition: number[] | null = null

    if (data) {
      data.forEach((item, index) => {
        if (newPosition !== null) return;
        if (item[0] === searchString) {
          newPosition = item[1]
          return newPosition // This return only returns from the forEach loop and not the wider {} block
        }
      });
    };

    console.log('IS THIS POSSIBLE? findNumbersArray failed:', searchString, data);
    return newPosition;
  };

  const emptyBoxText = loading ? "Searching..." : "No results found."
  // const borderColor = '#828282'
  // const borderColor = '#bbbbbb'
  const borderColor = '#ffffff'
  const backgroundColor = '#f9f9f9'

  function handlePanTo() {
    dispatch(triggerPanToCurrentLocation())
  }

  // const markerIcon = <IconMapPinFilled color={theme.colors.c3sRed[9]} />
  const leftSection = (
    <ActionIcon
      variant='transparent'
      color={'c3sRed'}
      onMouseDown={(event) => event.preventDefault()}
      onClick={handlePanTo}
    >
      <IconMapPinFilled color={theme.colors.c3sRed[9]} />
    </ActionIcon>
  )

  const handleGeolocate = () => {
    // Abort any previous geolocation if still active
    if (controller) {
      controller.abort();
    }

    const newController = new AbortController();
    setController(newController);  // Store the new controller in state
    const { signal } = newController;

    const getLocation = async () => {
      try {
        const result = await fetchGeolocation(signal);
        if (result) {
          // Replace this with your real update logic
          controller?.abort()
          dispatch(setHaveGeolocated(true))
          updatePositionAndData(result.latitude, result.longitude, routeNumber, null, 'geolocation');
        } else {
          console.log("GEO: Geolocation was not successful.");
        }
      } catch (error) {
        console.error("GEO: Error fetching geolocation:", error);
      }
    };

    getLocation();
  };

  // Abort geolocation if `state.test` changes
  useEffect(() => {
    if (controller) {
      controller.abort();  // Abort any ongoing geolocation
      console.log("GEO: Aborted due to Redux state change");
    }
    // Cleanup: when component unmounts or state changes, make sure to abort
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [geolocationToggle]);  // Watch for changes to state

  // const sequenceAbortController = useSequenceAbortController()[routeNumber].current

  const rightSection = (
    <div style={{
      width: '60px',
      height: '28px',
      position: "absolute",
      right: 3,
      top: '10%',
      flexWrap: "nowrap",
      gap: "4px",
      display: "flex",
      backgroundColor: `${backgroundColor}`
    }}>
      <ActionIcon
        variant='transparent'
        color={'c3sBlue'}
        styles={{
          root: {
            '--ai-hover-color': theme.colors.c3sBlue[6],
          }
        }}
        onClick={handleGeolocate}
      >
        {haveGeolocated ?
          <IconLocationFilled style={{ width: '70%', height: '70%' }} stroke={1.5} />
          :
          <IconLocation style={{ width: '70%', height: '70%' }} stroke={4} />
        }
      </ActionIcon>

      <ActionIcon
        variant='transparent'
        color={'c3sBlue'}
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => setValue('')}
        aria-label="Clear value"
      >
        {loading ?
          <Loader size={18} />
          :
          <IconX style={{ width: '70%', height: '70%' }} stroke={1.5} />
        }
      </ActionIcon>
    </div>
  )


  return (
    <Combobox
      offset={3}
      onOptionSubmit={(optionValue) => {
        setValue(optionValue);
        combobox.closeDropdown();

        // Dismiss the keyboard
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        const newPosition = findNumbersArray(optionValue);
        console.log('Option submitted:', optionValue, value, newPosition, data);
        if (newPosition !== null) {
          const newPositionStruct = { lat: newPosition[0][1], lng: newPosition[0][0] }
          // fetchDemoData(newPositionStruct.lat, newPositionStruct.lng)
          // dispatch(updatePosition(newPositionStruct))
          dispatch(openGraphs())
          // dispatch(updateName(optionValue))
          // fetchDemoData(newPositionStruct.lat, newPositionStruct.lng, "dropDown")
          updatePositionAndData(newPositionStruct.lat, newPositionStruct.lng, routeNumber, optionValue, 'dropdown')
        }
      }}
      withinPortal={false}
      store={combobox}
      size='md'
      // width={200}  // Required to avoid crashes due to resizing happening too often
      width={barWidth}
      styles={{
        options: {
          pointerEvents: 'auto',
          // width: barWidth,
        },
        dropdown: {
          borderRadius: '10px',
          // margin: '0 13px',
          borderColor: `${borderColor}`,
          borderWidth: "2px",
          backgroundColor: `${backgroundColor}`
        }
      }}
    >
      <Combobox.Target>
        <TextInput
          w={barWidth}
          // placeholder="🔍 Search location"
          placeholder="Search location"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            fetchOptions(event.currentTarget.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
            console.log("opening dropdown")
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            // handleMapClick('asd')
            combobox.openDropdown();
            if (data === null) {
              fetchOptions(value);
            }
          }}
          onBlur={() => {
            combobox.closeDropdown()
            console.log("closing dropdown on BLUR")
          }
          }
          leftSection={leftSection}
          rightSection={rightSection}
          styles={{
            root: {
              padding: '0',
              pointerEvents: 'auto',
              // minWidth: `${barWidth}px`,
              // maxWidth: `${barWidth}px`,
            },
            input: {
              borderRadius: '10px',
              pointerEvents: 'auto',
              color: 'black',
              borderColor: `${borderColor}`,
              borderWidth: "2px",
              backgroundColor: `${backgroundColor}`,
              boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.3)',
            },
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>{emptyBoxText}</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default memo(LocationSearchBar);