import { useEffect } from 'react'
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import useUpdatePositionAndData from './useUpdatePositionAndData.ts';
import { RootState } from '../reducers/store.ts';
import { getStateFromUrl } from '../utils/urlState.ts';
import { openGraphs } from '../reducers/graphSlice.ts';
import { isMobile } from 'react-device-detect';
import store from '../reducers/store.ts';
import { useSequenceAbortController } from './SequenceAbortControllerProvider.tsx';


const ArrivedFromURL = () => {
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const readLatLngFromURL = useTypedSelector(state => state.location.readLatLngFromURL)
  // const locationPosition = store.getState().location.locationPosition
  const dispatch = useDispatch()

  const { updatePositionAndData } = useUpdatePositionAndData();

  // const sequenceAbortControllers = useSequenceAbortController()

  useEffect(() => {
    if (readLatLngFromURL) {

      const urlState = getStateFromUrl();

      const lat = urlState.location.locationPosition.lat;
      const lng = urlState.location.locationPosition.lng;

      const lat2 = urlState.location.locationPosition2.lat;
      const lng2 = urlState.location.locationPosition2.lng;

      const comparisonMode = urlState.data.comparisonMode
      const routeA = comparisonMode ? 1 : 0
      console.log('DID arrivedFromURL', comparisonMode, lat, lng, lat2, lng2, readLatLngFromURL)

      updatePositionAndData(lat, lng, routeA, null, 'arrivedFromURL')

      if (lat2 !== null && lng2 !== null) {
        updatePositionAndData(lat2, lng2, 0, null, 'arrivedFromURL', true)
      }

      dispatch(openGraphs())
    } else {
      console.log('NOT arrivedFromURL')
      const locationPosition = store.getState().location.locationPosition
      const lat = locationPosition.lat;
      const lng = locationPosition.lng;
      updatePositionAndData(lat, lng, 0, null, 'NOT arrivedFromURL')

      if (!isMobile) {
        dispatch(openGraphs())
      }
    }
  }, [dispatch, updatePositionAndData, readLatLngFromURL]); // Add myStateVariable to dependencies

  return null
};

export default ArrivedFromURL;