import React from 'react';

import './App.css';
import Inputs from './components/Inputs.tsx';
import Outputs from './components/Outputs.tsx';
import Viewer from './components/Viewer.tsx';
import ArrivedFromURL from './components/ArrivedFromURL.tsx';

import { MantineProvider } from '@mantine/core';
import { TypographyStylesProvider } from '@mantine/core';
import Header from './components/Header.tsx';
import { Notifications } from '@mantine/notifications';
import { SequenceAbortControllerProvider } from './components/SequenceAbortControllerProvider.tsx';
import { NavigationProgress } from '@mantine/nprogress';

import '@mantine/core/styles.css';
import '@mantine/core/styles/global.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';

import store from './reducers/store.ts'
import { Provider } from 'react-redux'

import FPS from './components/FPS.tsx'
import ColorBars from './components/ColorBars.tsx';

import { theme } from './theme.tsx';

function App() {
  return (
    <>
      <SequenceAbortControllerProvider>
        <Provider store={store}>
          {/* <Geolocation /> */}

          <ArrivedFromURL />

          <div className="App">
            <MantineProvider theme={theme}>
              <NavigationProgress />
              
              <Notifications limit={1} />

              <TypographyStylesProvider>

                <Viewer />

                <FPS />

                {/* <div className="fadeBorder" /> */}

                <Header />

                <Inputs />

                <ColorBars />

                <Outputs />

              </TypographyStylesProvider>
            </MantineProvider>
          </div>
        </Provider>
      </SequenceAbortControllerProvider>
    </>
  );
}

export default App;
