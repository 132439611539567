

async function fetchZarrData(query: string, signal: AbortSignal) {
  // const server = "http://136.156.16.164:8001/"
  // const server = "http://192.168.1.80:8000/"
  // const server = "http://192.168.1.91:8000/"
  // const server = "https://9650-2a09-9180-fd-1-60f7-3513-b4fc-9ed.ngrok-free.app/"
  // const server = "http://136.156.133.151:6443/"
  const ngrokServer = "https://0838-2a09-9180-fd-1-f1f9-46bf-e962-3d4b.ngrok-free.app/"
  const vmServer = "https://136.156.133.151:6443/"
  const vmServer2 = "https://era-explorer.ecmwf-development.f.ewcloud.host/api/"
  const localServer = "https://136.156.12.30:8000/"
  const localServer2 = "http://192.168.0.124:8000/"

  // =================================================================================================
  // HERE is where you choose the server
  // ngrok server should work for all, but will have to be changed each time you restart ngrok
  // =================================================================================================
  
  const server = vmServer2

  // const server = process.env.BACKEND_URL || "/era-explorer/backend/" // Needed for real deployment but doesn't seem to work on my laptop
  console.log("server", server)
  const apiUrl = server + query

  console.log("ZARR request: ", apiUrl)
  
  try {
    // Make the API call using fetch with an abort signal
    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      // credentials: 'include',  // Needed for real deployment but doesn't seem to work on my laptop
      headers: {
        'Content-Type': 'text/plain',
        'ngrok-skip-browser-warning': "1",
        },
      signal
    });

    // console.log('ZARR', apiUrl)
    // console.log('ZARR response', response)

    // Check if the response is ok (status is in the range 200-299)
    if (!response.ok) {
      throw new Error('ZARR Network response was not ok');
    }

    // Convert the response to JSON
    const result = await response.json();

    // console.log('ZARR result', result)

    // Return the JSON data
    return result;
  } catch (error) {
    // Handle abort errors differently from other errors
    if (error.name === 'AbortError') {
      console.log('ZARR Fetch aborted');
    } else {
      console.error('ZARR Error fetching data:', error);
    }
    throw error;
  }
}

export default fetchZarrData;