import React, { useState, useEffect, useRef } from 'react'
import { MapContainer, WMSTileLayer, AttributionControl, useMapEvents, useMap } from 'react-leaflet';
import L, { } from 'leaflet'
import { closeGraphs, wiggleGraphs } from '../reducers/graphSlice.ts'
import { normalizeLongitude } from './locationHelpers.ts'

import LeafletVelocity from "./LeafletVelocity.tsx";

import 'leaflet/dist/leaflet.css';
// import 'leaflet-velocity'; // Import the leaflet-velocity plugin
import './Map.css';  // Ensure you have the CSS file for styling

import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import { toggleVelocity } from '../reducers/locationSlice.ts'
import useUpdatePositionAndData from './useUpdatePositionAndData.ts';
import { RootState } from '../reducers/store.ts';
import MyMarkers from './MyMarkers.tsx';
import { isMobile } from 'react-device-detect';
import CustomZoomButtons from './CustomZoomButtons.tsx'; // Import your custom buttons component

// import CustomCanvasLayer from './CustomCanvasLayer.tsx';

// Workaround because the leaflet css doesn't play well with react-leaflet
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
(L.Icon as any).Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// const arcticTiles = "https://tile.gbif.org/3575/omt/{z}/{x}/{y}@4x.png?style=osm-bright-en"
// const mercatorTiles = "https://{s}.tile.osm.org/{z}/{x}/{y}.png"

const mapStyle: React.CSSProperties = {
  position: 'fixed',
  left: '0px',
  right: '0px',
  top: '0px',
  bottom: '0px',
}

const PanToLocation = () => {
  useEffect(() => {
    console.log("Rendered PanToLocation")
  })

  const map = useMap();

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const position = useTypedSelector(state => state.location.locationPosition)
  const position2 = useTypedSelector(state => state.location.locationPosition2)
  const togglePanToLocation = useTypedSelector(state => state.location.togglePanToLocation)
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)
  const multipanel = useTypedSelector(state => state.graph.multipanel)

  const prevTogglePanToLocation = useRef(togglePanToLocation);

  useEffect(() => {
    let positionMod = {lat: position.lat, lng: position.lng}
    if ((prevTogglePanToLocation.current !== togglePanToLocation) && (position2.lat !== null && position2.lng !== null)) {
      console.log('togglePanToLocation changed');
      positionMod.lat = position2.lat
      positionMod.lng = position2.lng
    }

    prevTogglePanToLocation.current = togglePanToLocation; // Update the ref for the next render

    if (positionMod) {
      let landscape = true
      if ((window.innerWidth < window.innerHeight) || multipanel) {
        landscape = false
      }

      console.log("VIEWER PanToLocationX", positionMod)

      const zoom = map.getZoom()
      const degToRad = Math.PI / 180
      const latFactor = Math.cos(positionMod.lat * degToRad)
      const sizeFactor = window.innerHeight / 600
      const latOffset = (294.67 * Math.exp(-0.69 * zoom) - 0.48) * latFactor * sizeFactor
      const latOffsetMod = ((!landscape && graphsVisible) ? latOffset : 0)
      // const latOffsetMod = 0

      const bounds = map.getBounds()
      const westLng = bounds.getWest(); // Minimum longitude
      const eastLng = bounds.getEast(); // Maximum longitude
      const lonRange = eastLng - westLng
      const lonOffset = -0.15 * lonRange

      const lonOffsetMod = ((landscape && graphsVisible) ? lonOffset : 0)
      // const lonOffsetMod = 0

      const positionUpdated = { lat: positionMod.lat - latOffsetMod, lng: positionMod.lng - lonOffsetMod };

      // const mapCentre = map.getCenter()
      // const animate = (Math.abs(positionMod.lng - mapCentre.lng) > 180) ? false : true;
      // console.log("DEBUG", animate, mapCentre.lng, positionMod.lng)

      map.panTo(positionUpdated, {
        animate: true,        // Whether to animate the transition
        duration: 0.3,        // Duration of the transition in seconds
        easeLinearity: 1,   // How linear the easing function is (0 - 1)
      });
    }
  }, [map, position, togglePanToLocation, graphsVisible, multipanel, position2]);

  return null;
};


// Hook to handle map events
const MapEventsComponent = () => {
  useEffect(() => {
    console.log("Rendered/mounted MapEventsComponent")
    return () => {
      console.log("Unmounted MapEventsComponent");
    };
  }, []);

  const dispatch = useDispatch()
  const abortController = new AbortController()
  const { updatePositionAndData } = useUpdatePositionAndData();

  function handleZoomEnd() {
    console.log("Zoom ended")
    dispatch(toggleVelocity())
  }

  // function handleZoomStart() {
  //   console.log("Zoom started")
  //   if (isMobile) { return }
  //   dispatch(toggleVelocity())
  // }

  // function handleDragStart() {
  //   if (isMobile) { return }
  //   console.log("Dragging ended")
  //   dispatch(toggleVelocity())
  // }

  function handleDragEnd() {
    console.log("Dragging ended")

    // One of the things that was tried:..
    // if (map && test) {
    //   console.log("Dragging ended - adding layer")
    //   map.addLayer(test)
    // }

    dispatch(toggleVelocity())
  }

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)
  const routeNumber = useTypedSelector(state => state.data.routeNumber)

  // const sequenceAbortController = useSequenceAbortController()[routeNumber].current

  useMapEvents({
    dragend: () => { handleDragEnd() },
    zoomend: () => { handleZoomEnd() },
    // dragstart: () => { handleDragStart() },
    // zoomstart: () => { handleZoomStart() },

    click(e) {
      abortController.abort()

      dispatch(wiggleGraphs())

      if (isMobile) {
        // On mobile make a tap that occurs when the graphs are visible close the graphs
        // but on desktop don't worry about that and just change the location
        if (graphsVisible) {
          dispatch(closeGraphs())
          dispatch(toggleVelocity())
        } else {
          // dispatch(openGraphs())
          dispatch(toggleVelocity())
          const { lat, lng } = e.latlng;
          // dispatch(setGeolocated()); // Don't try and geolocate if we've already clicked away
          updatePositionAndData(lat, normalizeLongitude(lng), routeNumber, null, 'mapClick')
          console.log(`Clicked at latitude: ${lat}, longitude: ${lng}`);
        }
      } else {
        dispatch(toggleVelocity())
        const { lat, lng } = e.latlng;
        // dispatch(setGeolocated()); // Don't try and geolocate if we've already clicked away

        // const array = [0, 1];
        // const randomIndex = Math.floor(Math.random() * array.length);
        // const routeNumberRandom = array[randomIndex];

        updatePositionAndData(lat, normalizeLongitude(lng), routeNumber, null, 'mapClick')
        console.log(`Clicked at latitude: ${lat}, longitude: ${lng}`);
      }
    },
  });
  return null;
};

const AddAttribution = ({ attribution, attributionPosition }) => {
  useEffect(() => {
    console.log("Rendered AddAttribution")
  })

  const map = useMap();

  useEffect(() => {
    map.attributionControl.setPosition(attributionPosition)
    map.attributionControl.addAttribution(attribution);
  }, [map, attribution, attributionPosition]);

  return null;
};

const Viewer = () => {
  useEffect(() => {
    console.log("Rendered Viewer")
  })

  // const [showLeafletVelocity, setShowLeafletVelocity] = useState(true);

  // const MapEventsComponentCopilot = () => {
  //   const map = useMap();

  //   useEffect(() => {
  //     const handleTouchStart = () => {
  //       console.log("DEBUG touchstart");
  //       setShowLeafletVelocity(false);
  //     };

  //     const handleTouchEnd = () => {
  //       console.log("DEBUG touchend");
  //       setShowLeafletVelocity(true);
  //     };

  //     document.addEventListener('touchstart', handleTouchStart);
  //     document.addEventListener('touchend', handleTouchEnd);

  //     // Clean up event listeners on unmount
  //     return () => {
  //       document.removeEventListener('touchstart', handleTouchStart);
  //       document.removeEventListener('touchend', handleTouchEnd);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     const handleMoveStart = () => {
  //       console.log("DEBUG moveStart");
  //       setShowLeafletVelocity(false);
  //     };

  //     const handleMoveEnd = () => {
  //       console.log("DEBUG moveend");
  //       setShowLeafletVelocity(true);
  //     };

  //     const handleZoomStart = () => {
  //       console.log("DEBUG zoomstart");
  //       setShowLeafletVelocity(false);
  //     };

  //     const handleZoomEnd = () => {
  //       console.log("DEBUG zoomend");
  //       setShowLeafletVelocity(true);
  //     };

  //     map.on('movestart', handleMoveStart);
  //     map.on('moveend', handleMoveEnd);
  //     map.on('zoomstart', handleZoomStart);
  //     map.on('zoomend', handleZoomEnd);

  //     // Clean up event listeners on unmount
  //     return () => {
  //       map.off('movestart', handleMoveStart);
  //       map.off('moveend', handleMoveEnd);
  //       map.off('zoomstart', handleZoomStart);
  //       map.off('zoomend', handleZoomEnd);
  //     };
  //   }, [map]);

  //   return null;
  // };

  const corner1 = L.latLng(-79, -Infinity)
  const corner2 = L.latLng(82, Infinity)
  const bounds = L.latLngBounds(corner1, corner2)

  // Adjust zoom levels dynamically
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const maxViewportDimension = Math.max(viewportWidth, viewportHeight);

  let minZoom, maxZoom;
  let zoomSnap = 1
  const offset = 1
  if (maxViewportDimension < 700) {
    minZoom = 2
    maxZoom = 7 - offset
    zoomSnap = 0.5
  } else if (maxViewportDimension < 1000) {
    minZoom = 2.5
    maxZoom = 8 - offset
    zoomSnap = 0.5
  } else if (maxViewportDimension < 1200) {
    minZoom = 3
    maxZoom = 8 - offset
    zoomSnap = 1
    // } else if (maxViewportDimension < 1400) {
    //   minZoom = 3
    //   maxZoom = 8 - offset
    //   zoomSnap = 1
  } else if (maxViewportDimension < 1500) {
    minZoom = 3
    maxZoom = 9 - offset
    zoomSnap = 1
  } else if (maxViewportDimension < 2000) {
    minZoom = 3
    maxZoom = 9 - offset
    zoomSnap = 1
  } else {
    minZoom = 3
    maxZoom = 9 - offset
  }

  zoomSnap = 1

  const [landscape, setLandscape] = useState(window.innerHeight < window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setLandscape(window.innerHeight < window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const copernicusAttribution = "Powered by the <a href='https://cds-beta.climate.copernicus.eu/' target='_blank'><span style='color:#941333;'>C3S Climate Data Store</span></a>"
  const osmAttribution = "&copy <a href='https://www.openstreetmap.org/' target='_blank'><span>Open Street Map contributors</span></a>"
  // const attributions = `${osmAttribution}<br/>${copernicusAttribution}`
  const attributions = `${osmAttribution}, ${copernicusAttribution}`
  const attributionPosition = landscape ? "bottomright" : "bottomleft"

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
  const climatologyIndex = useTypedSelector(state => state.graph.climatologyIndex)
  const climatologyView = useTypedSelector(state => state.graph.climatologyView)
  const windEnabled = useTypedSelector(state => state.graph.windEnabled)

  let tempStyle, precipStyle, places_style, boundaries_style
  if (!spectralPalette) {
    tempStyle = 'matt_temp_mono'
    precipStyle = 'matt_precip_for_mono'
    places_style = 'matt_places_white'
    boundaries_style = 'matt_boundaries_black'
  } else {
    tempStyle = 'matt_temp_spectral'
    precipStyle = 'matt_precip_for_spectral'
    places_style = 'matt_places_black'
    boundaries_style = 'matt_boundaries_grey'
  }

  let temperatureLayer: string
  let precipLayer: string
  let mapTime = ''
  if (!climatologyView) {
    temperatureLayer = 'era:t2m'
    precipLayer = 'era:tp'
  } else {
    temperatureLayer = 'era:t2m_clim'
    precipLayer = 'era:tp_clim'
    mapTime = `2000-${(climatologyIndex + 1).toString().padStart(2, '0')}-15T00:00:00.000000000Z`
  }

  // ============================
  // This section buffers changes to ClimatologyIndex, to avoid crashes in the velocity layer if
  // it mounts and unmounts too quickly
  // ============================
  const [climatologyIndexMod, setClimatologyIndexMod] = useState<number>(-1);

  // Ref to track the last update time
  const lastUpdateTimeRef = useRef<number | null>(null);
  // Ref to store the buffered change
  const bufferedValueRef = useRef<number | null>(null);
  // Ref to store the timer ID
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const now = Date.now();
    const lastUpdateTime = lastUpdateTimeRef.current;

    // Check if the last update happened more than 2 seconds ago
    if (!lastUpdateTime || now - lastUpdateTime >= 2000) {
      // Update climatologyIndexMod immediately
      setClimatologyIndexMod(climatologyIndex);
      lastUpdateTimeRef.current = now;
    } else {
      // If we are within the 2-second window, buffer the update
      bufferedValueRef.current = climatologyIndex;

      // Clear any previous timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a timer to apply the buffered value after the 2-second window
      timerRef.current = setTimeout(() => {
        if (bufferedValueRef.current !== null) {
          setClimatologyIndexMod(bufferedValueRef.current);
          lastUpdateTimeRef.current = Date.now();
          bufferedValueRef.current = null; // Clear the buffered value
        }
      }, 2000 - (now - lastUpdateTime)); // Only wait for the remaining time
    }

    // Cleanup on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [climatologyIndex]);
  // ============================
  // ============================

  console.log('LAYER INFO', temperatureLayer, precipLayer, mapTime, climatologyIndexMod)

  // const cloudLayerRef = useRef(null);  // Reference to the WMSTileLayer
  // const avgOpacity = 0.6;  // Average opacity for the cloud layer
  // const opacityRange = 0.2;  // Range of opacity oscillation

  // useEffect(() => {
  //   let animationFrameId;
  //   let tick = 0;

  //   const animateOpacity = () => {
  //     tick += 0.01;  // Speed of opacity change
  //     const newOpacity = avgOpacity + opacityRange * Math.sin(tick);  // Oscillating opacity between 0.5 and 0.7

  //     // Set the new opacity directly on the WMSTileLayer using Leaflet's API
  //     if (cloudLayerRef.current) {
  //       cloudLayerRef.current.setOpacity(newOpacity);  // Directly control opacity
  //     }

  //     animationFrameId = requestAnimationFrame(animateOpacity);
  //   };

  //   // Start the animation
  //   animationFrameId = requestAnimationFrame(animateOpacity);

  //   return () => {
  //     cancelAnimationFrame(animationFrameId);  // Cleanup animation frame on unmount
  //   };
  // }, []);

  return (
    <MapContainer
      style={mapStyle}
      attributionControl={false}
      zoomControl={false}
      zoomSnap={zoomSnap}
      center={{ lat: 0, lng: 0 }}
      zoom={minZoom}
      bounceAtZoomLimits={true}
      maxBounds={bounds}
      maxBoundsViscosity={0}
      minZoom={minZoom}
      maxZoom={maxZoom}
      dragging={true}
      inertia={false}
      worldCopyJump={true}
    // crs={L.CRS.EPSG4326}
    >

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        format='image/png'
        pane="tilePane"
        transparent={false}
        params={{
          layers: temperatureLayer,
          // @ts-ignore
          time: mapTime,
          styles: tempStyle,
        }}
      />

      {!spectralPalette &&
        <WMSTileLayer
          url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
          format='image/png'
          pane="tilePane"
          transparent={true}
          params={{
            layers: precipLayer,
            // @ts-ignore
            time: mapTime,
            styles: precipStyle,
          }}
        />
      }

      {/* <CustomCanvasLayer /> */}

      {/* <WMSTileLayer
          url='https://cds.climate.copernicus.eu/geoserver/gwc/service/wms?'
          layers='basic:coastlines__resolution_high'
          transparent={true}
          format='image/png'
          pane="shadowPane"
        /> */}
      {/* 
      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/gwc/service/wms?'
        layers='ne:coastlines'
        transparent={true}
        format='image/png'
        pane="shadowPane"
      />
      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/gwc/service/wms?'
        layers='ne:boundary_lines'
        transparent={true}
        format='image/png'
        pane="shadowPane"
      /> */}

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        params={{
          layers: 'era:ne_10m_admin_0_earthkit',
          styles: boundaries_style,
        }}
      />

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        params={{
          layers: "ne:populated_places",
          styles: places_style,
          width: 512,
          height: 512,
        }}
      />

      {/* <WMSTileLayer
          url='https://cds.climate.copernicus.eu/geoserver/gwc/service/wms?'
          layers='basic:states_provinces__resolution_high'
          transparent={true}
          format='image/png'
          pane="shadowPane"
          opacity={0.2}
        />
        <WMSTileLayer
          url='https://cds.climate.copernicus.eu/geoserver/gwc/service/wms?'
          layers='basic:lakes__resolution_high'
          transparent={true}
          format='image/png'
          pane="shadowPane"
          opacity={0.2}
        />
        <WMSTileLayer
          url='https://cds.climate.copernicus.eu/geoserver/gwc/service/wms?'
          layers='basic:rivers__resolution_high'
          transparent={true}
          format='image/png'
          pane="shadowPane"
          opacity={0.2}
        /> */}

      <AttributionControl
        prefix={false}
      />

      {landscape && <AddAttribution attribution={attributions} attributionPosition={attributionPosition} />}

      {landscape && <CustomZoomButtons />}

      <MapEventsComponent />

      {/* <MapEventsComponentCopilot /> */}

      <MyMarkers />

      <PanToLocation />

      {/* <LayersControl position="topright">
          <LayersControl.Overlay name="Climatological winds">


          </LayersControl.Overlay>
        </LayersControl> */}

      {windEnabled && <LeafletVelocity climatologyIndex={climatologyIndexMod} />}


    </MapContainer>
  )
}

export default Viewer
