import React, { memo, useEffect, useState } from 'react';
import { ActionIcon, Tooltip, Group, Title, Divider, Stack, Space, Drawer, Box } from '@mantine/core';
import {
    IconAdjustments,
    IconMap,
    IconLogicXor
} from '@tabler/icons-react';
import { isMobile } from 'react-device-detect';
import { useDisclosure } from '@mantine/hooks';
import ToggleLayers from './ToggleLayers.tsx';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../reducers/store.ts';
import { TRANSLUCENT_COLOR } from './constants.ts';
import { notifications } from '@mantine/notifications';
import { useDispatch } from 'react-redux';
import { toggleComparisonMode } from '../reducers/dataSlice.ts'
import { toggleClimatologyView, toggleMultipanel } from '../reducers/graphSlice.ts';
import ToggleSwitch from './ToggleSwitch.tsx';
import { storeSecondaryPosition, clearSecondaryPosition } from '../reducers/locationSlice.ts';

function ShowSettings({ buttonWidth }) {
    useEffect(() => {
        console.log('Rendered ShowSettings')
    })

    const [opened, { open, close }] = useDisclosure(false);

    const titleButtonWidth = 35

    const tooltipLabel = 'Settings and options'

    const buttonWidthSmall = isMobile ? 50 : 55
    const vGap = 10

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    // const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)  // T or P layer
    const spectralPalette = true
    const comparisonMode = useTypedSelector((state) => state.data.comparisonMode)
    const climatologyView = useTypedSelector((state) => state.graph.climatologyView)
    const multipanel = useTypedSelector((state) => state.graph.multipanel)
    // const fetchingData = useTypedSelector((state) => state.data.fetchingData)

    // These mean the state is only updated when the modal is closed, so the animations remain smooth
    const [comparisonModeState, setComparisonModeState] = useState(comparisonMode)
    const [climatologyViewState, setClimatologyViewState] = useState(climatologyView)

    const dispatch = useDispatch()

    const graphsOnBottom = (window.innerWidth < window.innerHeight)

    const title = (
        <Group>
            <ActionIcon
                size={titleButtonWidth}
                onClick={handleClose}
            >
                <IconAdjustments style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>

            <Title order={3}>
                <b>ERA explorer</b>
            </Title>
        </Group>

    )

    function handleComparisonModeClick() {
        // if (fetchingData) {
        //     notifications.show({
        //         title: 'ERA-explorer: Comparison mode',
        //         message: 'Downloading data for current location, please wait...',
        //         position: 'top-center',
        //         loading: true,
        //         withBorder: true,
        //         radius: 'xl',
        //         limit: 3,
        //     })
        //     return
        // }

        setComparisonModeState(prev => !prev)
    }

    function handleClimatologyViewClick() {
        setClimatologyViewState(prev => !prev)
    }

    function handleMultipanelViewClick() {
        dispatch(toggleMultipanel())
    }

    // useEffect(() => {
    //     if (!fetchingData) {
    //         notifications.cleanQueue()
    //     }
    // }, [fetchingData])

    function handleClose() {
        // THis is because if we dispatch this from the switch it seems to hang the UI briefly and the switch animation doesn't work
        if (comparisonModeState !== comparisonMode) {
            dispatch(toggleComparisonMode())

            if (comparisonModeState) {
                dispatch(storeSecondaryPosition())
            } else {
                dispatch(clearSecondaryPosition())
            }
        }
        if (climatologyViewState !== climatologyView) {
            dispatch(toggleClimatologyView())
        }
        close()
    }

    const boxWidth = '20%'
    const lineSize = 'sm'
    const smallGap = '0px'

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const drawAtBottom = windowSize.width < 500

    // const fetchingAnyData = fetchingData.some(val => val);

    return (
        <>
            <Tooltip label={tooltipLabel}>
                <ActionIcon
                    size={buttonWidth}
                    onClick={open}
                    color={spectralPalette ? TRANSLUCENT_COLOR : 'c3sGrey'}
                >
                    <IconAdjustments style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
            </Tooltip>

            <Drawer
                position={drawAtBottom ? 'bottom' : 'left'}
                opened={opened}
                onClose={handleClose}
                title={title}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                size={drawAtBottom ? 'sm' : 'md'}
                styles={{
                    root: {
                        '--drawer-height': (!drawAtBottom ? '440px' : null),
                    }
                }}
                // style={{zIndex: 10000}}
            // keepMounted={true}
            >
                <Divider
                    size={lineSize}
                    labelPosition="left"
                    label={
                        <>
                            <IconMap size={12} />
                            <Box ml={5}>Map layers</Box>
                        </>
                    }
                />
                <Space h='sm' />

                <Group justify='space-between' style={{ width: '80%', margin: "0px 30px" }} data-autofocus>
                    <Stack align='center' justify='flex-start' gap={vGap} style={{ minWidth: boxWidth, maxWidth: boxWidth, width: boxWidth }} >
                        <ToggleLayers buttonWidth={buttonWidthSmall} layer={'temperature'} />
                        Temperature
                        <Space h={smallGap} />
                    </Stack>

                    <Stack align='center' justify='flex-start' gap={vGap} style={{ minWidth: boxWidth, maxWidth: boxWidth, width: boxWidth }} >
                        <ToggleLayers buttonWidth={buttonWidthSmall} layer={'rain'} />
                        Rain
                        <Space h={smallGap} />
                    </Stack>

                    <Divider orientation='vertical' size={lineSize} />

                    <Stack align='center' justify='flex-start' gap={vGap} style={{ minWidth: boxWidth, maxWidth: boxWidth, width: boxWidth }} >
                        <ToggleLayers buttonWidth={buttonWidthSmall} layer={'wind'} />
                        Wind
                        <Space h={smallGap} />
                    </Stack>
                </Group>

                <Space h='sm' />

                <Divider
                    size={lineSize}
                    labelPosition="left"
                    label={
                        <>
                            <IconLogicXor size={12} />
                            <Box ml={5}>Advanced</Box>
                        </>
                    }
                />
                <Space h='sm' />

                <ToggleSwitch
                    handleClick={handleComparisonModeClick}
                    label={"Compare two locations"}
                    defaultChecked={comparisonModeState}
                    // disabled={fetchingAnyData}
                />

                <Space h='md' />

                <ToggleSwitch
                    handleClick={handleClimatologyViewClick}
                    label={"Show long-term averages of individual months instead of full years"}
                    defaultChecked={climatologyView}
                />

                {!isMobile &&
                    <>
                        <Space h='md' />

                        <ToggleSwitch
                            handleClick={handleMultipanelViewClick}
                            label={"Show graphs on the bottom of the screen"}
                            defaultChecked={multipanel}
                            disabled={graphsOnBottom}
                        />
                    </>
                }
            </Drawer >
        </>
    );
}

export default memo(ShowSettings);
