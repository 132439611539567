import React, { useState, useRef, useEffect, memo } from 'react'
import { Carousel, CarouselProps, Embla } from '@mantine/carousel';
import { useDispatch } from 'react-redux';
import { updateActiveSlide } from '../reducers/dataSlice.ts'
import '@mantine/carousel/styles.css';
import classesHoriz from './CarouselHoriz.module.css';
import classesVert from './CarouselVert.module.css';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import ScienceBox from './ScienceBox.tsx';
import { isMobile } from 'react-device-detect';
// import Autoplay from 'embla-carousel-autoplay';
import { IconArrowLeft, IconArrowRight, IconArrowUp, IconArrowDown } from '@tabler/icons-react';
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import ToggleGraphs from './ToggleGraphs.tsx';
import { toggleGraphs } from '../reducers/graphSlice.ts';
import { TOTAL_SLIDES, TRANSLUCENT_COLOR, variableOptions } from './constants.ts';
import store, { RootState } from '../reducers/store.ts';
import { usePreventPullToRefreshDiv } from './usePreventPullToRefresh.ts';

function MyCarousel({ windowSize, landscape, slideWidth, slideHeight, multipanel }) {
  useEffect(() => {
    console.log("Rendered MyCarousel")
  })

  const [embla, setEmbla] = useState<Embla | null>(null);

  const wheelGestures = useRef(WheelGesturesPlugin());

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const slidesVisible = useTypedSelector(state => state.data.slidesVisible)

  const dispatch = useDispatch();

  useEffect(() => {
    const captureIndex = () => {
      const newActiveSlide = embla?.internalEngine().index.get() ?? 0
      console.log("newActiveSlide", newActiveSlide, store.getState().data.prioritySlide)  // This is always in the range 0<nVisibleSlides (which can get smaller)
      dispatch(updateActiveSlide(newActiveSlide))
    }
    embla?.on("select", captureIndex);
    return () => {
      embla?.off("select", captureIndex);
    };
  }, [embla, dispatch]);

  let height = slideHeight

  let orientation: CarouselProps["orientation"] = 'horizontal'

  let slideGap, buttonMargin
  let gradientDirection, blurPadding
  let nextControlIcon, prevControlIcon

  if (landscape && !multipanel) {
    // If landscape then put scroll on the right
    height = window.innerHeight
    orientation = 'vertical'

    slideGap = slideHeight * 0.01

    gradientDirection = 'left'
    blurPadding = '0px 10px 0px 10px'
    buttonMargin = "10px 0px 0px 0px"

    nextControlIcon = <IconArrowDown />
    prevControlIcon = <IconArrowUp />
  } else {
    // If portrait (i.e. mobile) then put scroll on the bottom and ensure that the overall height of the slide won't be too large
    slideGap = slideWidth * 0.02
    orientation = 'horizontal'
    height = slideHeight

    gradientDirection = "top"
    blurPadding = '10px 0px 10px 0px'
    buttonMargin = "0px 10px 0px 0px"


    nextControlIcon = <IconArrowRight />
    prevControlIcon = <IconArrowLeft />
  }

  const blurContainerStyle = {
    WebkitBackdropFilter: 'blur(2px)', /* Adjust the blur level */
    backdropFilter: 'blur(2px)', /* Adjust the blur level */
    willChange: "transform, backdrop-filter",
    background: `linear-gradient(to ${gradientDirection}, rgba(255, 255, 255, 0.3) 98%, rgba(255, 255, 255, 0) 100%)`,
    padding: `${blurPadding}`,
  }

  const plugins = landscape ? [wheelGestures.current] : undefined

  const plotToShow = useTypedSelector(state => state.data.plotToShow)
  const [initialSlide, setInitialSlide] = useState(0)
  useEffect(() => {
    if ((slidesVisible.filter(Boolean).length === TOTAL_SLIDES) && (plotToShow !== null)) {
      // All slides are visible, we might have just arrived to set the initial slide
      setInitialSlide(plotToShow)
    }
  }, [plotToShow, slidesVisible])

  const flexDirection = landscape ? "column" : (isMobile ? "row" : "row-reverse")

  const buttonWidth = 45

  const buttonsFlexStyle: React.CSSProperties = {
    margin: buttonMargin,
    flexWrap: "wrap",
    justifyContent: "flex-start",
    flexDirection: flexDirection,
    display: "flex",
    columnGap: "10px",
    rowGap: "10px",
    marginLeft: "0px",
    padding: "0px 0px 0px 10px"
  }

  const tapToCloseStyle: React.CSSProperties = {
    width: "100%",
    height: '40px',
    backgroundColor: '#ff000000',
    // height: '250px',
    // backgroundColor: '#ff000066',
    position: 'absolute',
    pointerEvents: "auto",
    top: `${buttonWidth - 15}px`,
    zIndex: 1000,
  }

  const [isListening, setIsListening] = useState(true); // State to trigger re-renders if needed
  const [startY, setStartY] = useState<number | null>(null);
  const isListeningRef = useRef(true); // Ref for immediate state updates
  const minDragDist = 10
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)

  const handleTouchMove = (event) => {
    // Only proceed if we are still listening
    if (isListeningRef.current && isListening) {
      // console.log("Touch move detected:", event.touches[0].clientX, event.touches[0].clientY);
      // Stop listening after detecting the first touch move

      if (startY) {
        if ((((startY - event.touches[0].clientY) > minDragDist) && (!graphsVisible)) ||
          (((event.touches[0].clientY - startY) > minDragDist) && (graphsVisible))) {
          dispatch(toggleGraphs());

          // Update ref immediately
          isListeningRef.current = false;
          // Optionally update state for re-render purposes
          setIsListening(false);
        }
      }
    }
  };

  const handleTouchStart = (event) => {
    // console.log("Touch start, ready to capture next move.");

    // Reset ref immediately
    isListeningRef.current = true;
    // Optionally update state for re-render purposes
    setIsListening(true);
    setStartY(event.touches[0].clientY);
  };

  // Create a ref for the specific element where you want to prevent pull-to-refresh
  const dragToCloseRef = usePreventPullToRefreshDiv();

  // const slideData = [
  //   { variable: "Precip monthly climatology", mutuallyExclusive: false },
  //   { variable: "Temperature monthly climatology", mutuallyExclusive: false },
  //   { variable: "Frost days etc", mutuallyExclusive: false },
  //   { variable: "Precip annual timeseries", mutuallyExclusive: false },
  //   { variable: "Temperature annual timeseries", mutuallyExclusive: false },
  //   { variable: "Temperature warming stripes", mutuallyExclusive: false },
  //   { variable: "Wind annual climatology", mutuallyExclusive: true },
  //   { variable: "Precip hourly hist", mutuallyExclusive: false },
  //   { variable: "Temperature daily climatology", mutuallyExclusive: true },
  // ];

  return (
    <>
      <div style={buttonsFlexStyle}>
        <ToggleGraphs buttonWidth={buttonWidth} />
      </div>

      {(isMobile && !landscape) &&
        <div
          style={tapToCloseStyle}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          ref={dragToCloseRef}
        />
      }

      <div style={blurContainerStyle}>
        <Carousel
          controlSize={60}
          withIndicators
          height={height}
          orientation={orientation}
          slideSize={{ base: `${slideWidth}` }}
          slideGap={slideGap}
          nextControlIcon={nextControlIcon}
          previousControlIcon={prevControlIcon}
          loop
          initialSlide={initialSlide}
          slidesToScroll={1}
          align='center'
          classNames={landscape ? classesVert : classesHoriz}
          plugins={plugins}
          getEmblaApi={setEmbla}
          styles={{
            root: {
            },
            indicator: {
              backgroundColor: "#828282",
            }
          }}
        >

          {variableOptions.map((slide, index) =>
            slidesVisible[index] && (
              <Carousel.Slide key={index} hidden={!slidesVisible[index]}>
                <ScienceBox
                  width={slideWidth}
                  height={slideHeight}
                  variable={slide.name}
                  landscape={landscape}
                  buttonIndex={index}
                  mutuallyExclusive={slide.mutuallyExclusive}
                />
              </Carousel.Slide>
            )
          )}

        </Carousel>
      </div>
    </>
  );
}

export default memo(MyCarousel)