const getDynamicText = (variable, period) => {
    const period_sanitised = (period !== null) ? period : "1991-2020";

    let text = "UNSET";
    switch (variable) {
        case "Temperature daily climatology":
            text = `For each day of the year, the <b>minimum</b> and <b>maximum</b> <b>temperatures</b> are calculated.
                    This is repeated for each year in the climatology period (${period_sanitised}).
                    The average value over all years (<i>e.g.</i> all 1st Januaries) is shown.`;
            break;
        case "Precip monthly climatology":
            text = `For each month of the year, the <b>total rainfall</b> is calculated.
                    This is repeated for each month in the climatology period (${period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.`;
            break;
        case "Temperature monthly climatology":
            text = `For each day in a month, the <b>minimum</b> and <b>maximum</b> <b>temperatures</b> are calculated.
                    The average of these values over the <b>whole month</b> is calculated.
                    This is repeated for each year in the climatology period (${period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.`;
            break;
        case "Frost days etc":
            text = `For each day in a month, the <b>minimum</b> and <b>maximum</b> <b>temperatures</b> are calculated.
                    These are then compared against the temperature thresholds for <b>Frost Days</b> (minimum temperature below 0 °C),
                    <b>Summer Days</b> (maximum temperature above 30 °C), and <b>Tropical Nights</b> (minimum temperature above 25 °C).
                    The <b>percentage of days</b> in each month that reach the thresholds are calculated.
                    This is repeated for each year in the climatology period (${period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.`;
            break;
        case "Precip annual timeseries":
            text = `For each year, the <b>total rainfall</b> is calculated by summing the accumulated rainfall over every hour and day in the year.`;
            break;
        case "Temperature annual timeseries":
            text = `For each year, the <b>average temperature</b> is calculated.`;
            break;
        case "Temperature warming stripes":
            text = `For each year, the <b>average temperature</b> is calculated.
                    Subsequently, the average temperature over the climatology period (${period_sanitised}) is subtracted.
                    The <b>anomalous temperature</b> is shown.
                    The colour scaling follows that of <b><a href="https://showyourstripes.info" target="_blank">#ShowYourStripes</a></b> but uses a normalisation period of 1940-1999 due to data availability.`;
            break;
        case "Wind annual climatology":
            text = `For each hour, the <b>wind speed</b> and <b>wind direction</b> are calculated.
                    These are then binned into one of <b>16 direction categories</b> and one of <b>4 speed categories</b>.
                    This is repeated for the entire climatology period (${period_sanitised}).
                    The percentage of values in each category is shown.
                    This is called a <b>wind rose</b> and shows the direction the wind is <i>coming from</i>.`;
            break;
        case "Precip hourly hist":
            text = `For each period of one hour, the <b>total rainfall</b> is calculated.
                    This is repeated for every hour in the climatology period (${period_sanitised}).
                    The number of times the rainfall falls within a certain range is then counted.
                    The percentage of the total hours in which the rainfall falls within each range is shown.`;
            break;
        default:
            break;
    }

    return text;
};

const getExtraText = (variable) => {
    // This is extra text that can give tips about what to look for/at in a variable

    // const intro = '<strong style="font-size: 0.85em; color: #570101;">EXPLORE:</strong>'
    const intro = '';
    const settingsIcon = `
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments inline-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M6 10m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                        <path d="M6 4l0 6"></path>
                        <path d="M6 12l0 8"></path>
                        <path d="M12 16m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                        <path d="M12 4l0 10"></path>
                        <path d="M12 18l0 2"></path>
                        <path d="M18 7m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                        <path d="M18 4l0 1"></path>
                        <path d="M18 9l0 11"></path>
                        </svg>
                        `

    let text = "UNSET";
    switch (variable) {
        case "Temperature monthly climatology":
        case "Temperature daily climatology":
            text = `${intro} Compare locations ${settingsIcon} near the ocean with those further inland.
                    Due to its high <b>heat capacity</b>, water heats up and cools down more slowly than land, so temperatures over the ocean are often more stable than those in landlocked regions.`;
            break;
        case "Frost days etc":
            text = `${intro} Compare locations ${settingsIcon} near the ocean with those further inland.
                    Due to its high <b>heat capacity</b>, water heats up and cools down more slowly than land, so temperatures over the ocean are often more stable than those in landlocked regions.
                    Some coastal regions might rarely hit these thresholds, while inland areas could experience both hot and cold extremes throughout the year.`;
            break;
        case "Precip hourly hist":
        case "Precip monthly climatology":
        case "Precip annual timeseries":
            text = `${intro} Compare locations ${settingsIcon} on different sides of mountain ranges, like the Andes in South America.
                    <b>Orographic lift</b> as air rises over mountains increases the relative humidity.
                    This can lead to more rain on the side facing the wind, while the other side stays drier.`;
            break;
        case "Temperature annual timeseries":
        case "Temperature warming stripes":
            text = `${intro} Compare locations ${settingsIcon} near the equator with those near the poles.
                    Climate change caused by <b>human activities</b> is generally warming the planet but poleward <b>heat transport</b> by the atmosphere and oceans means areas closer to the poles are warming faster than those near the equator.`;
            break;
        case "Wind annual climatology":
            text = `${intro} Compare <b>wind roses</b> for monsoon regions ${settingsIcon} such as South Asia with the midlatitude oceans.
                    Monsoon winds can change direction throughout the year, while winds between around 30° and 60° latitude generally blow from the west (<i>"westerlies"</i>).
                    These westerly winds are driven by the <b>Coriolis effect</b> (Earth's rotation) and <b>differential heating</b> of the Earth's surface by the sun.`;
            break;
        default:
            break;
    }

    return text;
};

const getBonusText = () => {
    const text = `
                All of the climate statistics generated and visualised by this application are derived from the <b>ERA5</b> reanalysis dataset,
                which is available to download and use under the Copernicus licence.
                <b>ERA5</b> is a global <b>atmospheric reanalysis</b> that covers the period from 1940 to present and is developed and maintained by <b>ECMWF</b>.
                This application uses the <b>hourly, 31km</b> high resolution reanalysis, interpolated to a regular 0.25° × 0.25° grid.
                The information presented by this application for specific locations is based on the <b>nearest grid point</b> to the given location in the <b>ERA5</b> reanalysis and is not based on site-specific observations.`
    return text;
};

const getDisclaimerText = () => {
    const text = `
                The designations employed and the presentation of material on the map do not imply the expression of any opinion whatsoever on the part of the <b>European Union</b> concerning the legal status of any country, territory or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.`;
    return text;
};

const getHiddenText = () => {
    const text = `
                Based on the ERA5 reanalysis, developed and maintained by ECMWF.`;
    return text;
};

const getECMWFText = () => {
    const text = `
                We are both a <b>research institute</b> and a <b>24/7 operational service</b>, producing global numerical weather predictions and other data 
                for our <b>Member and Co-operating States</b> and the broader community. We operate a <b>world-class supercomputer facility</b> for weather forecasting 
                and hold one of the <b>largest meteorological data archives</b>.`;
    return text;
};

export { getDynamicText, getBonusText, getExtraText, getHiddenText, getECMWFText, getDisclaimerText };
