// src/utils/urlState.js
import queryString from 'query-string';
import { clampLatLng } from './clamp';
import { DEFAULT_LOCATION, variableOptions } from '../components/constants.ts';

export const getStateFromUrl = () => {
  const parsed = queryString.parse(window.location.search);
  const lat = parsed.lat ? Number(parsed.lat) : null;
  const lng = parsed.lng ? Number(parsed.lng) : null;
  const latB = parsed.latB ? Number(parsed.latB) : null;
  const lngB = parsed.lngB ? Number(parsed.lngB) : null;
  const plotIndex = parsed.plot ? Number(parsed.plot) : null;

  // const locationPosition = store.getState().location.locationPosition;
  let clampedLat = DEFAULT_LOCATION.lat
  let clampedLng = DEFAULT_LOCATION.lng
  // let haveGeolocated = false;
  let readLatLngFromURL = false

  if (lat !== null && lng !== null) {
    const clamped = clampLatLng(lat, lng);
    clampedLat = clamped.lat || DEFAULT_LOCATION.lat;
    clampedLng = clamped.lng || DEFAULT_LOCATION.lng;
    // haveGeolocated = true;
    readLatLngFromURL = true
  }

  const comparisonMode = (latB !== null && lngB !== null);

  const clampedB = clampLatLng(latB, lngB);

  const maxPlotIndex = variableOptions.length - 1;
  const plotIndexClamped = (plotIndex ? (Math.min(Math.max(plotIndex, 0), maxPlotIndex)) : null);

  console.log("getStateFromUrl", comparisonMode, { lat, lng }, { latB, lngB }, { plotIndex });

  return {
    location: {
      locationPosition: {
        lat: clampedLat,
        lng: clampedLng,
      },
      locationPosition2: {
        lat: clampedB.lat,
        lng: clampedB.lng,
      },
      // haveGeolocated,
      readLatLngFromURL,
    },

    data: {
      plotToShow: plotIndexClamped,
      comparisonMode: comparisonMode,
    },
  };
};

export const updateUrlFromState = ({
  location: {
    locationPosition,
    locationPosition2,
  },
  data: {
    prioritySlide
  }
}) => {
  const newLat = locationPosition.lat.toFixed(2);
  const newLng = locationPosition.lng.toFixed(2);
  const newLatB = (locationPosition2.lat ? locationPosition2.lat.toFixed(2) : null)
  const newLngB = (locationPosition2.lng ? locationPosition2.lng.toFixed(2) : null)
  const newPlot = prioritySlide.toFixed(0);

  const currentUrlParams = new URLSearchParams(window.location.search);
  const currentLat = currentUrlParams.get('lat');
  const currentLng = currentUrlParams.get('lng');
  const currentLatB = currentUrlParams.get('latB');
  const currentLngB = currentUrlParams.get('lngB');
  const currentPlot = currentUrlParams.get('plot');

  if (newLat !== currentLat || newLng !== currentLng || newPlot !== currentPlot || newLatB !== currentLatB || newLngB !== currentLngB) {
    console.log("updateUrlFromState",
      { lat: newLat, lng: newLng },
      { latB: newLatB, lngB: newLngB },
      { plot: newPlot },
      { lat: currentLat, lng: currentLng },
      { latB: currentLatB, lngB: currentLngB },
      { plot: currentPlot }
    );

    let newUrl: string
    if (newLatB !== null && newLngB !== null) {
      newUrl = queryString.stringify({ lat: newLat, lng: newLng, latB: newLatB, lngB: newLngB, plot: newPlot });
    } else {
      newUrl = queryString.stringify({ lat: newLat, lng: newLng, plot: newPlot });

    }
    window.history.replaceState(null, '', `?${newUrl}`);
  }
};