import React, { memo, useState, useEffect, useRef } from 'react';
import { ActionIcon, Tooltip, Indicator } from '@mantine/core';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import { toggleGraphs } from '../reducers/graphSlice.ts'
import { resetWiggle } from '../reducers/graphSlice.ts'
import { RootState } from '../reducers/store.ts';
import { usePreventPullToRefreshButton } from './usePreventPullToRefresh.ts';
import { IconBellFilled, IconChartAreaFilled, IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconStar } from '@tabler/icons-react';
import { isMobile } from 'react-device-detect';

function ToggleGraphs({ buttonWidth }) {
  const dispatch = useDispatch()
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const fetchingData = useTypedSelector(state => state.data.fetchingData)
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)
  const multipanel = useTypedSelector(state => state.graph.multipanel)
  const doSpin = useTypedSelector(state => state.graph.graphWiggle)

  const className = doSpin ? "spinButton" : "";

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    dispatch(toggleGraphs())
  }

  const tooltipLabel = "Show/hide graphs"

  // State for visibility of indicator
  const [indicatorVisible, setIndicatorVisible] = useState<boolean>(false);

  const fetchingAnyData = fetchingData.some(val => val);

  // Ref to store the previous value of fetchingData
  const prevFetchingDataRef = useRef(fetchingAnyData);

  useEffect(() => {
    // Compare the previous value of fetchingData to the current one
    if (prevFetchingDataRef.current && !fetchingAnyData) {
      // fetchingData just changed from true to false
      setIndicatorVisible(true);
    }
    // Update the ref value with the current fetchingData for the next comparison
    prevFetchingDataRef.current = fetchingAnyData;
  }, [fetchingAnyData]);

  useEffect(() => {
    // Reset indicatorVisible to false whenever graphsVisible changes
    setIndicatorVisible(false);
  }, [graphsVisible]);


  const buttonRef = usePreventPullToRefreshButton()

  const iconSize = '90%'

  const disabled = !indicatorVisible || fetchingAnyData || graphsVisible
  // const disabled = false

  const graphsOnBottom = isMobile || multipanel

  const iconSize2 = '90%'
  const label = <IconBellFilled style={{ width: iconSize2, height: iconSize2 }} stroke={1} />
  // const indicatorPosition = !isMobile ? 'top-start' : 'top-end'
  const indicatorPosition = 'top-start'

  return (
    <Tooltip label={tooltipLabel}>
      <Indicator inline label={label} processing disabled={disabled}  color="c3sRed" offset={6} size={20} position={indicatorPosition}>
        <ActionIcon
          className={className}
          size={buttonWidth}
          onClick={handleClick}
          onAnimationEnd={() => dispatch(resetWiggle())}
          ref={buttonRef}
          color={'c3sGrey'}
        >
          {graphsVisible ? (
            (graphsOnBottom) ?
              <IconChevronDown style={{ width: iconSize, height: iconSize }} stroke={1.5} />
              :
              <IconChevronRight style={{ width: iconSize, height: iconSize }} stroke={1.5} />
          )
            :
            (graphsOnBottom) ?
              <IconChevronUp style={{ width: iconSize, height: iconSize }} stroke={1.5} />
              :
              <IconChevronLeft style={{ width: iconSize, height: iconSize }} stroke={1.5} />
          }
        </ActionIcon>
      </Indicator>
    </Tooltip>
  );
}

export default memo(ToggleGraphs);
