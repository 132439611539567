import { useRef, useCallback, useMemo } from 'react';
// import fetchZarrData from './fetchZarrData.ts'
// import fetchPlaceNames from './fetchPlaceNames.ts'
import { useDispatch } from 'react-redux'
import { fetchPlaceName } from './OSM_API.ts'
import { updateName } from '../reducers/locationSlice.ts'
import { updateNamesInData } from '../reducers/dataSlice.ts'
import { formatLocation, normalizeLongitude } from './locationHelpers.ts'


const useFindNearestPlace = () => {
    const ac1 = useRef<AbortController | null>(null)
    const ac2 = useRef<AbortController | null>(null)
    const abortController = useMemo(() => [ac1, ac2], [])
    const dispatch = useDispatch()

    const findNearestPlace = useCallback((lat: number, lng: number, routeNumber: number, debug: string) => {
        console.log(`findNearestPlace: Called by ${debug}`)
        // dispatch(updateName("Loading..."));

        abortController[routeNumber].current?.abort();
        abortController[routeNumber].current = new AbortController();

        const longitudeWrapped = normalizeLongitude(lng)
        const query = `reverse?format=geocodejson&lat=${lat}&lon=${longitudeWrapped}&addressdetails=1&zoom=12`;
        const defaultName = formatLocation(lat, lng)

        fetchPlaceName(query, defaultName, abortController[routeNumber].current.signal)
            .then((result) => {
                dispatch(updateName(result));

                const newLocationInfo = {
                    placeName: result,
                    position: { lat: lat, lng: lng },
                };
                dispatch(updateNamesInData({
                    routeNumber: routeNumber,
                    locationInfo: newLocationInfo
                }));

                abortController[routeNumber].current = null;
            })
            .catch(() => {
                dispatch(updateName("Error loading location"));
            });
    }, [dispatch, abortController]);

    return { findNearestPlace };
};

export default useFindNearestPlace;
