import React, { memo, useEffect } from 'react';
import { Switch } from '@mantine/core';
import { theme } from '../theme.tsx';


function ToggleSwitch({ handleClick, label, defaultChecked=true, disabled=false}) {
    useEffect(() => {
        console.log("Rendered ToggleSwitch")
    })

    return (
        <Switch
            label={label}
            size="xl"
            onLabel="ON"
            offLabel="OFF"
            color={theme.colors.c3sRed[9]}
            onClick={handleClick}
            defaultChecked={defaultChecked}
            disabled={disabled}
            styles={{
                label: {
                    fontSize: '1rem',
                },
                track: {
                    width: '75px',
                    minWidth: '75px',
                    maxWidth: '75px',
                    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.3)',
                },
                thumb: {
                    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.3)',
                },
            }}
        />
    );
}

export default memo(ToggleSwitch);
