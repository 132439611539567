import { Slider } from '@mantine/core';
import React, { memo, useState } from 'react';
import classes from './MySlider.module.css';
import { IconLetterASmall, IconLetterJSmall, IconLetterMSmall, IconLetterOSmall, IconLetterFSmall,
    IconLetterSSmall, IconLetterNSmall, IconLetterDSmall } from '@tabler/icons-react';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { updateClimatologyIndex } from '../reducers/graphSlice.ts';
import { RootState } from '../reducers/store.ts';
import { theme } from '../theme.tsx';

const iconStroke = 2
const iconSize = '130%'
const iconStyle = { width: iconSize, height: iconSize, minWidth: iconSize, minHeight: iconSize }
const minWidth = 400

function MySlider({ sliderWidth }) {
    const defaultValue = 0
    const [sliderValue, setSliderValue] = useState(defaultValue);  // Initialize state with default value

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
    const climatologyView = useTypedSelector(state => state.graph.climatologyView)

    const dispatch = useDispatch()

    const handleChange = (value) => {
        setSliderValue(value);
        dispatch(updateClimatologyIndex(value))
    }

    const markLabelColor = spectralPalette ? 'black' : 'black'

    const rotate = sliderWidth < minWidth
    const labelStyle: React.CSSProperties = {
        rotate: `${rotate ? 35 : 0}deg`,
    }

    const iconColor = 'white'

    const marks = [
        { value: 0, label: <div style={labelStyle}>Jan</div>, letter: 'J', icon: <IconLetterJSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 1, label: <div style={labelStyle}>Feb</div>, letter: 'F', icon: <IconLetterFSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 2, label: <div style={labelStyle}>Mar</div>, letter: 'M', icon: <IconLetterMSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 3, label: <div style={labelStyle}>Apr</div>, letter: 'A', icon: <IconLetterASmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 4, label: <div style={labelStyle}>May</div>, letter: 'M', icon: <IconLetterMSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 5, label: <div style={labelStyle}>Jun</div>, letter: 'J', icon: <IconLetterJSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 6, label: <div style={labelStyle}>Jul</div>, letter: 'J', icon: <IconLetterJSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 7, label: <div style={labelStyle}>Aug</div>, letter: 'A', icon: <IconLetterASmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 8, label: <div style={labelStyle}>Sep</div>, letter: 'S', icon: <IconLetterSSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 9, label: <div style={labelStyle}>Oct</div>, letter: 'O', icon: <IconLetterOSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 10, label: <div style={labelStyle}>Nov</div>, letter: 'N', icon: <IconLetterNSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 11, label: <div style={labelStyle}>Dec</div>, letter: 'D', icon: <IconLetterDSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
    ];

    const markWidth = sliderWidth / 15

    return (
        <div style={{ visibility: (climatologyView ? "visible" : "hidden") }}>
            <div
                style={{
                    backgroundColor: 'transparent',
                    width: `${sliderWidth}px`,
                    height: '40px',
                    position: 'relative',
                    top: '0px',
                    left: '-7px',
                    zIndex: 1,
                }}
            />

            <div style={{position: "relative", top: -27, zIndex: 1}}>
                <Slider
                    thumbChildren={(marks.find((mark) => mark.value === sliderValue)!.icon)}
                    defaultValue={defaultValue}
                    label={null}
                    step={1}
                    marks={marks}
                    min={defaultValue}
                    max={11}
                    value={sliderValue}
                    onChange={handleChange}
                    classNames={classes}
                    styles={{
                        markLabel: {
                            color: markLabelColor,
                            textAlign: 'center',
                            fontWeight: "700",
                            transform: `translateX(-${!rotate ? (markWidth / 3) : 3}px) translateY(4px)`,
                            textShadow:
                                `1px 1px 0px white,
                        -1px 1px 0px white,
                        1px -1px 0px white,
                        -1px -1px 0px white,
                        1px 0px 0px white,
                        -1px 0px 0px white,
                        0px 1px 0px white,
                        0px -1px 0px white`,
                        },
                        mark: {
                            width: `${markWidth}px`,
                            height: `${markWidth}px`,
                            transform: "translateX(-33%) translateY(-35%)",
                            borderWidth: 0,
                            borderColor: 'black',
                            backgroundColor: theme.colors.c3sBlue[9],
                        },
                        thumb: {
                            height: 40,
                            width: 40,
                            backgroundColor: theme.colors.c3sRed[9],
                            color: 'black',
                            borderWidth: 0,
                            boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.3)',
                        },
                        bar: {
                            opacity: 0,
                        },
                    }}
                />
            </div>
        </div>
    );
}

export default memo(MySlider);