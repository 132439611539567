import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'react-leaflet';

function MyTooltip({ content }) {
    useEffect(() => {
        console.log("Rendered MyTooltip")
    })

    if (isMobile) {
        return null
    }

    return (
        <Tooltip
            direction="bottom"
            offset={[0, 3]}
            opacity={1}
            permanent={false}
            content={content}
        />
    );
};

export default MyTooltip;