import React, { memo } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import { toggleSpectralPalette, toggleWind } from '../reducers/graphSlice.ts'
import { RootState } from '../reducers/store.ts';
import { IconTemperatureSun, IconCloudRain, IconWind } from '@tabler/icons-react';

function ToggleLayers({ buttonWidth, layer }) {
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const dispatch = useDispatch()

  const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
  const windEnabled = useTypedSelector(state => state.graph.windEnabled)
  const tooltipLabel = `Show ${layer}`


  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (layer === 'wind') {
      dispatch(toggleWind())
    } else {
      dispatch(toggleSpectralPalette())
    }
  }

  let color = 'c3sGrey.1'
  let borderColor = 'black'
  let iconColor = 'black'
  if ((layer === 'temperature') && spectralPalette) {
    color = 'c3sRed'
    borderColor = color
    iconColor = 'white'
  } else if ((layer === 'rain') && !spectralPalette) {
    color = 'c3sBlue'
    borderColor = color
    iconColor = 'white'
  } else if ((layer === 'wind') && windEnabled) {
    color = 'c3sYellow'
  }
  console.log("DEBUG", layer, spectralPalette, color, borderColor)

  let icon
  switch (layer) {
    case "temperature":
      icon = <IconTemperatureSun style={{ width: '70%', height: '70%' }} stroke={1.5} color={iconColor} />
      break
    case "rain":
      icon = <IconCloudRain style={{ width: '70%', height: '70%' }} stroke={1.5} color={iconColor} />
      break
    case "wind":
      icon = <IconWind style={{ width: '70%', height: '70%' }} stroke={1.5} color={iconColor} />
      break
    default:
      throw new Error("Invalid layer: " + layer)
  }

  return (
    <Tooltip label={tooltipLabel}>
      <ActionIcon
        size={buttonWidth}
        color={color}
        onClick={handleClick}
      >
        {icon}
      </ActionIcon>
    </Tooltip>
  );
}

export default memo(ToggleLayers);
