import React, { memo, useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../reducers/store.ts';
import { Polyline, useMap } from 'react-leaflet';

function DirectionArrowSimple({ current }) {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const comparisonMode = useTypedSelector(state => state.data.comparisonMode);
    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette);

    const currentOrPrevious = current ? 0 : 1
    const currentOrPreviousInverse = !current ? 0 : 1
    const position = useTypedSelector(state => state.data.tempAnn[currentOrPrevious].metadata.locationPosition);
    const positionInverse = useTypedSelector(state => state.data.tempAnn[currentOrPreviousInverse].metadata.locationPosition);

    const fixedPixelLength = 5; // Fixed length of the line in pixels (visual length)
    const arrowHeadLength = 12; // Length of the arrowhead
    const arrowHeadAngle = 30; // Angle of the arrowhead (degrees)
    // const color = !current ? c3sblue : c3sred;
    const color = spectralPalette ? 'black' : 'white';

    const map = useMap();

    const [linePoints, setLinePoints] = useState<LatLng[]>([]);
    // const [arrowHeadPoints, setArrowHeadPoints] = useState<LatLng[]>([]);
    const [zoom, setZoom] = useState(map.getZoom()); // Track zoom level in state

    useEffect(() => {
        // Zoom event listener
        const handleZoomEnd = () => {
            setZoom(map.getZoom()); // Update zoom state when zoom changes
        };

        // Attach event listener
        map.on('zoomend', handleZoomEnd);

        // Clean up the event listener when the component unmounts or when map changes
        return () => {
            map.off('zoomend', handleZoomEnd);
        };
    }, [map]);

    useEffect(() => {
        // If position data is not available, return early
        if (!position || !positionInverse || !comparisonMode) {
            setLinePoints([]);
            // setArrowHeadPoints([]);
            return;
        }

        // Convert lat/lng to pixel coordinates at the current zoom level
        const point1 = map.project([position.lat, position.lng], zoom);
        const point2 = map.project([positionInverse.lat, positionInverse.lng], zoom);

        // Calculate the direction vector (point2 - point1)
        const dx = point2.x - point1.x;
        const dy = point2.y - point1.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance !== 0) {
            // Normalize the direction vector
            const unitVectorX = dx / distance;
            const unitVectorY = dy / distance;

            // Convert fixed pixel length to degrees at the current zoom level
            const metersPerPixel = map.getScaleZoom(zoom); // Get meters per pixel at current zoom level
            const fixedLengthInDegrees = fixedPixelLength * metersPerPixel;

            // Scale the normalized vector to the desired real-world distance (in degrees)
            const scaledX = point1.x + unitVectorX * fixedLengthInDegrees;
            const scaledY = point1.y + unitVectorY * fixedLengthInDegrees;

            // Convert the scaled pixel coordinates back to geographical coordinates
            const endLatLng = map.unproject([scaledX, scaledY], zoom);

            // Calculate the arrowhead points
            const angleRad = (arrowHeadAngle * Math.PI) / 180;
            const cosAngle = Math.cos(angleRad);
            const sinAngle = Math.sin(angleRad);
            const cosAngleRev = Math.cos(-angleRad);
            const sinAngleRev = Math.sin(-angleRad);

            // First arrowhead point (rotated clockwise)
            const arrowPoint1X = scaledX - arrowHeadLength * (cosAngle * unitVectorX - sinAngle * unitVectorY);
            const arrowPoint1Y = scaledY - arrowHeadLength * (sinAngle * unitVectorX + cosAngle * unitVectorY);
            const arrowHeadPoint1 = map.unproject([arrowPoint1X, arrowPoint1Y], zoom);

            // Second arrowhead point (rotated counter-clockwise)
            const arrowPoint2X = scaledX - arrowHeadLength * (cosAngleRev * unitVectorX - sinAngleRev * unitVectorY);
            const arrowPoint2Y = scaledY - arrowHeadLength * (sinAngleRev * unitVectorX + cosAngleRev * unitVectorY);
            const arrowHeadPoint2 = map.unproject([arrowPoint2X, arrowPoint2Y], zoom);

            // Set the points for the polyline and the arrowhead
            setLinePoints([(position as LatLng), endLatLng, arrowHeadPoint1, endLatLng, arrowHeadPoint2]);
            // setArrowHeadPoints([endLatLng, arrowHeadPoint1, endLatLng, arrowHeadPoint2]);
        }
    }, [map, position, positionInverse, comparisonMode, fixedPixelLength, zoom]);

    // Render the polyline with the arrowhead
    return (
        <>
            {(linePoints.length === 5) && (
                <Polyline
                    positions={linePoints}
                    noClip={true}
                    className='directionArrow'
                    pathOptions={{
                        color: color,
                        weight: 4,
                        opacity: 1,
                        lineCap: 'round',
                        lineJoin: 'round',
                        fill: true,
                    }}
                />
            )}
        </>
    );
}

export default memo(DirectionArrowSimple);