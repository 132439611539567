// import { useState, useEffect } from 'react'
// import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
// import useUpdatePositionAndData from './useUpdatePositionAndData.ts';
// import store, { RootState } from '../reducers/store.ts';

// interface GeolocationResult {
//   latitude: number;
//   longitude: number;
// }

// // Function to get the user's location
// const fetchGeolocation = (): Promise<GeolocationResult> => {
//   return new Promise((resolve, reject) => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           resolve({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude
//           });
//         },
//         (error) => {
//           console.log("GEO: Error getting location");
//           // const storePosition = store.getState().location.locationPosition;
//           // resolve({
//           //   latitude: storePosition.lat,
//           //   longitude: storePosition.lng,
//           // });
//           reject(error)
//         }
//       );
//     } else {
//       // TODO: Move the updatePosition stuff here so we use the default location rather than leaving the graphs blank.
//       // OR we could only do that if we OPEN the graphs on the default location - probably better
//       console.log("GEO: Geolocation is not supported by this browser.");
//       // const storePosition = store.getState().location.locationPosition;
//       // resolve({
//       //   latitude: storePosition.lat,
//       //   longitude: storePosition.lng,
//       // });
//       reject("Geolocation is not supported by this browser.")
//     }
//   });
// };

// // Function to get the user's location
// const fetchGeolocationOld = (): Promise<{ latitude: number; longitude: number }> => {
//   return new Promise((resolve, reject) => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           resolve({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude,
//           });
//         },
//         (error) => {
//           console.error("GEO: Error getting location", error);
//           reject(error);  // Properly reject the promise on error
//         }
//       );
//     } else {
//       // Geolocation not supported by the browser
//       console.error("GEO: Geolocation is not supported by this browser.");
//       reject(new Error("Geolocation not supported by browser"));
//     }
//   });
// };


// const Geolocation = () => {
//   const [position, setPosition] = useState<GeolocationResult | null>(null);
//   // const [placeName, setPlaceName] = useState(null);
//   const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
//   const haveGeolocated = useTypedSelector(state => state.location.haveGeolocated)
//   const dispatch = useDispatch()

//   const { updatePositionAndData } = useUpdatePositionAndData();

//   // const { fetchDemoData } = useFetchDemoData();

//   // const averageTemp = useSelector(state => state.data.averageTemp)

//   useEffect(() => {
//     // Function to handle the async call to get geolocation
//     const getLocation = async () => {
//       try {
//         const result = await fetchGeolocation();
//         setPosition(result);
//       } catch (error) {
//         console.error("GEO: Error fetching geolocation:", error);
//       }
//     };

//     getLocation();
//   }, []); // Empty dependency array ensures this runs once on mount

//   useEffect(() => {
//     if (position !== null) {
//       // This effect runs when location changes (i.e., async geolocation call finishes)
//       // Here you can perform actions based on the latest state value
//       if (!haveGeolocated) {
//         // If we haven't yet geolocated, or clicked somewhere:
//         console.log("Haven't geolocated or clicked so updating", haveGeolocated)
//         updatePositionAndData(position.latitude, position.longitude, null, 'geolocation')
//         // dispatch(updatePosition({ lat: position.latitude, lng: position.longitude }));
//         // fetchDemoData(position.latitude, position.longitude, "geolocation");
//       } else {
//         console.log("Geolocated already so will not update");
//       }
//     }
//   }, [position, haveGeolocated, dispatch, updatePositionAndData]); // Add myStateVariable to dependencies

//   return null
// };

// // Custom hook for geolocation
// const useGeolocation = () => {
//   const [location, setLocation] = useState<GeolocationResult | null>(null);
//   const [error, setError] = useState<string | null>(null);

//   const fetchGeolocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLocation({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude,
//           });
//         },
//         (err) => {
//           console.error("GEO: Error getting location", err);
//           setError("Error getting geolocation: " + err.message);
//         }
//       );
//     } else {
//       console.error("GEO: Geolocation is not supported by this browser.");
//       setError("Geolocation is not supported by this browser.");
//     }
//   };

//   return { location, error, fetchGeolocation };
// };

// Your geolocation function with abort controller
const fetchGeolocation = (
  signal: AbortSignal
): Promise<{ latitude: number; longitude: number }> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (!signal.aborted) {  // Only resolve if not aborted
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          }
        },
        (error) => {
          if (!signal.aborted) {  // Reject only if not aborted
            console.error("GEO: Error getting location", error);
            reject(error);
          }
        }
      );

      // Abort handler
      signal.addEventListener("abort", () => {
        console.log("GEO: Geolocation request aborted.");
        reject(new Error("Geolocation request aborted by user."));
      });
    } else {
      console.error("GEO: Geolocation is not supported by this browser.");
      reject(new Error("Geolocation not supported by browser"));
    }
  });
};

export default fetchGeolocation;