import React, { useState, useEffect } from 'react'
import LocationSearchBar from './LocationSearchBarFree.tsx';
import { Group } from '@mantine/core';
import ShowAbout from './ShowAbout.tsx';
import ShowSettings from './ShowSettings.tsx';
import { MARGIN } from './constants.ts';
import MySlider from './MySlider.tsx';
import { usePreventPullToRefreshDiv } from './usePreventPullToRefresh.ts';

const Inputs = () => {
    console.log("RENDERING Inputs")

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const topMargin = 10
    const margin = MARGIN
    const buttonWidth = 40


    const gap = topMargin
    const barWidth = Math.min(windowSize.width - 2 * margin - 2 * buttonWidth - 2 * gap, 350)

    const barAndButtonsWidth = barWidth + 2 * buttonWidth + 2 * gap
    let sliderWidth
    let divStyle: React.CSSProperties = {}
    if (windowSize.width > barAndButtonsWidth) {
        sliderWidth = barAndButtonsWidth
        const markOverflow = 7
        divStyle.marginLeft = `${margin + markOverflow}px`
        divStyle.width = `${barAndButtonsWidth - (margin)}px`
    } else {
        sliderWidth = windowSize.width * 0.9
        divStyle.marginLeft = "5%"
        divStyle.width = '90%'
    }

    const divRef = usePreventPullToRefreshDiv()

    return (
        <div ref={divRef}>
            <Group
                align="flex-start"
                gap={gap}
                style={{
                    margin: `${topMargin}px ${margin}px`,
                }}
            >
                <ShowSettings buttonWidth={buttonWidth} />
                <LocationSearchBar barWidth={barWidth} />
                <ShowAbout buttonWidth={buttonWidth} />
            </Group>

            <div style={divStyle}>
                <MySlider sliderWidth={sliderWidth} />
            </div>
        </div>
    );
}

export default Inputs