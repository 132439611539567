import { Title } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../reducers/store.ts';

const Header = () => {
    console.log("RENDERING Header")

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const climatologyView = useTypedSelector(state => state.graph.climatologyView)
    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)

    const [landscape, setLandscape] = useState(window.innerHeight < window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setLandscape(window.innerHeight < window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const stylesLandscape = {
        root: {
            position: 'fixed',
            top: '10px',
            width: '100%',
            justifyContent: 'center',   /* Horizontally center the text */
            alignItems: 'center',      /* Vertically center the text */
            color: (spectralPalette ? '#000000' : '#FFFFFF'),
            opacity: 0.6,
            margin: 0,
            fontSize: '2rem',
            visibility: (climatologyView ? 'hidden' : 'visible'),
        }
    }
    const stylesPortrait = {
        root: {
            position: 'fixed',
            left: '-70px',
            top: '250px',
            color: (spectralPalette ? '#000000' : '#FFFFFF'),
            opacity: 0.5,
            margin: 0,
            rotate: '270deg',
            // zIndex: 100,
        }
    }

    const stylesAPI = landscape ? stylesLandscape : stylesPortrait

    return (
        <Title
            order={3}
            lineClamp={1}
            // @ts-ignore  For the win!
            styles={stylesAPI}
        >
            ERA Explorer BETA
        </Title>
    );
};

export default Header;
