import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStateFromUrl } from '../utils/urlState.ts';
import { DEFAULT_LOCATION } from '../components/constants.ts';

// Define the state type
interface LocationState {
    locationName: string;
    locationPosition: { lat: number; lng: number };
    locationPosition2: { lat: number | null; lng: number | null };
    // locationNamePreviousData: string;
    // locationNameCurrentData: string;
    haveGeolocated: boolean;
    readLatLngFromURL: boolean;
    doSpin: boolean;
    velocityToggle: boolean;
    geolocating: boolean
    geolocationToggle: boolean
    togglePanToLocation: boolean
    // talkingToOSM: boolean;
}

// Default initial state
const defaultInitialState: LocationState = {
    locationName: "Unknown",
    locationPosition: DEFAULT_LOCATION, // Default to Brussels
    locationPosition2: { lat: null, lng: null },
    // locationNamePreviousData: "Unknown",
    // locationNameCurrentData: "Unknown",
    haveGeolocated: false,
    readLatLngFromURL: false,
    doSpin: false,
    velocityToggle: true,
    geolocating: false,
    geolocationToggle: true,
    togglePanToLocation: true,
    // talkingToOSM: false,
};

// Get initial state from URL and merge with default initial state
const urlState = getStateFromUrl();
const initialState: LocationState = {
    ...defaultInitialState,
    locationPosition: urlState.location.locationPosition,
    locationPosition2: urlState.location.locationPosition2,
    // haveGeolocated: urlState.location.haveGeolocated,
    readLatLngFromURL: urlState.location.readLatLngFromURL,
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        updatePosition: (state, action: PayloadAction<{ lat: number; lng: number }>) => {
            state.locationPosition = action.payload;
            state.geolocating = false  // This will cancel any geolocation requests
        },
        storeSecondaryPosition: state => {
            // throw new Error('Not implemented')
            state.locationPosition2.lat = state.locationPosition.lat
            state.locationPosition2.lng = state.locationPosition.lng
        },
        clearSecondaryPosition: state => {
            if ((state.locationPosition2.lat !== null) && (state.locationPosition2.lng !== null)) {
                state.locationPosition.lat = state.locationPosition2.lat
                state.locationPosition.lng = state.locationPosition2.lng
            }
            state.locationPosition2.lat = null
            state.locationPosition2.lng = null
        },
        switchCurrentAndPreviousLocation: state => {
            const location1 = state.locationPosition
            const location2 = state.locationPosition2
            if ((location2.lat !== null) && (location2.lng !== null)) {
                state.locationPosition = {
                    lat: location2.lat,
                    lng: location2.lng
                }
                state.locationPosition2 = location1
            }
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.locationName = action.payload;
            state.doSpin = true;
        },
        setHaveGeolocated: (state, action: PayloadAction<boolean>) => {
            state.haveGeolocated = action.payload
            console.log("Set haveGeolocated", action.payload)
        },
        // setLocationNameCurrentData: (state) => {
        //     if (state.locationNameCurrentData !== state.locationName) {
        //         state.locationNamePreviousData = state.locationNameCurrentData
        //         state.locationNameCurrentData = state.locationName
        //     }
        // },
        // setGeolocated: state => {
        //     state.haveGeolocated = true
        // },
        triggerPanToCurrentLocation: state => {
            state.togglePanToLocation = !state.togglePanToLocation
        },
        resetSpin: state => {
            state.doSpin = false
        },
        toggleVelocity: (state) => {
            state.velocityToggle = !state.velocityToggle
        },
        setGeolocating: state => {
            state.geolocating = true
        },
        cancelGeolocation: state => {
            state.geolocationToggle = !state.geolocationToggle
        }
        // talkingToOSM: (state, action: PayloadAction<boolean>) => {
        //     state.talkingToOSM = action.payload
        //     if (action.payload) {
        //         state.locationName = 'Loading...'
        //     }
        // },
    }
})

export const {
    updatePosition, storeSecondaryPosition,
    clearSecondaryPosition, updateName,
    resetSpin, toggleVelocity,
    switchCurrentAndPreviousLocation, setGeolocating,
    setHaveGeolocated, cancelGeolocation,
    triggerPanToCurrentLocation,
} = locationSlice.actions

export default locationSlice.reducer