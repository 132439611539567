import { getDaysLong } from './dateHelpers.ts'
import { stripeColorsWhite, dailyPolarColors, cumulDays, months_short, windColors, windColors2 } from './constants.ts';
import { theme } from '../theme.tsx'

interface Annotation {
    x: number;
    y: number;
    xref: string;
    yref: string;
    showarrow: boolean;
    text: string;
    font: {
        color: string;
        weight: number;
        size: number;
    };
}

function truncateString(str, landscape) {
    if (str === null) {
        return "Loading..."
    }

    let maxLengthOverall = 15
    if (landscape) {
        maxLengthOverall = 20
    }
    const maxLengthWithoutDots = maxLengthOverall - 1
    if (str.length > maxLengthWithoutDots && str.length >= maxLengthOverall) {
        return str.slice(0, maxLengthWithoutDots) + "..";
    }
    return str;
}

function yvalsToColors(yvals) {
    const yvalsMin = Math.min(...yvals)
    const yvalsAnom = yvals.map(val => val - yvalsMin)
    const yvalsRange = Math.max(...yvalsAnom) / 2
    const yvalsScaled = yvalsAnom.map(val => (val / yvalsRange) - 1)
    const markerColor = yvalsScaled.map(
        function (value) {
            return getStripeColor(value, 'BlueToRed')
        }
    )
    return markerColor
}

function getStripeColor(value: number, colorScale = 'Hawkins') {

    let theseStripeColors: string[] = []
    if (colorScale === 'Hawkins') {
        theseStripeColors = stripeColorsWhite
    } else if (colorScale === 'BlueToRed') {
        // https://leonardocolor.io/scales.html#
        // Must be odd!
        theseStripeColors = dailyPolarColors
    } else {
        throw new Error(`Unknown color scale: ${colorScale}`)
    }

    const halfLength = (theseStripeColors.length - 1) / 2
    const indexRaw = value * halfLength
    const indedRounded = Math.round(indexRaw)
    const indexShifted = indedRounded + halfLength
    const indexClamped = Math.min(Math.max(0, indexShifted), theseStripeColors.length - 1)

    // console.log("getStripeColor", indexClamped, theseStripeColors[indexClamped])
    return theseStripeColors[indexClamped]
}

function getDataMinMax(yvals, base, yvals2, base2) {
    // Calculate yvalsPbase and yvalsMn based on the base values
    const yvalsPbase = base ? yvals.map((num, idx) => num + base[idx]) : [];

    // Calculate dataMin and dataMax for the plot
    let dataMin = base ? Math.min(...base) : 0; // or some other default value
    let dataMax = Math.max(...yvalsPbase);

    if (base2 !== null) {
        dataMin = Math.min(dataMin, Math.min(...base2));
    }
    if (yvals2 !== null) {
        const yvals2Pbase2 = base2 ? yvals2.map((num, idx) => num + base2[idx]) : [];
        dataMax = Math.max(dataMax, Math.max(...yvals2Pbase2));
    }

    const dataMinPlot = dataMin - 5;
    const dataMaxPlot = dataMax;
    return { dataMinPlot, dataMaxPlot };
}

function generatePolarData(yvals, base, yvals2, base2, xvals, dataRange, show, monthIndex) {
    // Define cumulative days and scaling factor
    const scaling = 360 / 366;

    // Initialize dataToPlot array
    const dataToPlot: Record<string, any>[] = [];

    const text = getDaysLong();

    // const highlightColor = theme.colors.c3sYellow[9] + '44'
    const highlightColor = theme.colors.c3sRed[9] + '22'

    if ((base !== null) && (yvals !== null)) {
        let stripesColors: string[] = Array(yvals.length).fill("#00000033");
        let zorder = 10
        if (show[0]) {
            const yvalsMn = base ? yvals.map((num, idx) => (num + base[idx]) / 2) : [];
            stripesColors = yvalsToColors(yvalsMn);
            zorder = 2000
        }

        // Prepare text and hovertemplate for the plot
        const hovertemplate = '%{text}<br><b>Min: </b>%{base:.1f} °C<br><b>Max</b>: %{customdata:.1f} °C';
        const customdata = base?.map((val, i) => val + yvals[i]);

        // Create the actual data for plotting
        const actualData = {
            type: "barpolar",
            mode: "lines",
            r: yvals,
            base: base,
            theta: xvals,
            fill: "toself",
            marker: { color: stripesColors },
            name: "",
            zorder: zorder,
            text: text,
            hovertemplate: hovertemplate,
            textposition: "none",
            customdata: customdata,
        };

        // Push the actual data to the dataToPlot array
        if (show[0]) {
            // Put at the end if we want to have it on top
            dataToPlot.push(actualData);
        } else {
            dataToPlot.unshift(actualData);
        }
    }

    if ((base2 !== null) && (yvals2 !== null)) {
        let stripesColors: string[] = Array(yvals2.length).fill("#00000033");
        if (show[1]) {
            const yvalsMn = base2 ? yvals2.map((num, idx) => (num + base2[idx]) / 2) : [];
            stripesColors = yvalsToColors(yvalsMn);
        }

        // Prepare text and hovertemplate for the plot
        const hovertemplate = '%{text}<br><b>Min: </b>%{base:.1f} °C<br><b>Max</b>: %{customdata:.1f} °C';
        const customdata = base2?.map((val, i) => val + yvals2[i]);

        // Create the actual data for plotting
        const actualData = {
            type: "barpolar",
            mode: "lines",
            r: yvals2,
            base: base2,
            theta: xvals,
            fill: "toself",
            marker: { color: stripesColors },
            name: "",
            zorder: 10,
            text: text,
            hovertemplate: hovertemplate,
            textposition: "none",
            customdata: customdata,
        };

        // Push the actual data to the dataToPlot array
        if (show[1]) {
            dataToPlot.push(actualData);
        } else {
            dataToPlot.unshift(actualData);
        }
    }

    let iStart = 0
    if (monthIndex !== null) {
        if (monthIndex % 2 === 0) {
            iStart = 0
        } else {
            iStart = 1
        }
    }

    // Generate plot data for each stripe
    // This has to be done after the real data or hovertext doesn't work properly
    for (let i = iStart; i < (cumulDays.length - 1); i ++) {
        let r = [dataRange.dataMinPlot];
        let theta = [cumulDays[i] * scaling];
        const jfinal = cumulDays[i + 1] + 1;
        for (let j = cumulDays[i]; j < jfinal; j++) {
            r.push(dataRange.dataMaxPlot);
            theta.push(j * scaling);
        }
        r.push(dataRange.dataMinPlot);
        theta.push(jfinal);

        // const opacity = (i === monthIndex) ? 0.15 : 0.05;
        const opacity = ((i % 2) === 0) ? 0.05 : 0
        const fillColor = (i !== monthIndex) ? `rgba(0, 0, 0, ${opacity})` : highlightColor

        const newData = {
            type: "scatterpolar",
            mode: "lines",
            r: r,
            color: 'transparent',
            theta: theta,
            fill: "toself",
            // fillcolor: `rgba(0, 0, 0, ${opacity})`,
            fillcolor: fillColor,
            line: {
                color: 'transparent',
            },
            name: "",
            zorder: -20,
        };
        dataToPlot.unshift(newData);
    }

    // Return the final dataToPlot array
    return dataToPlot;
}

function generatePolarLayout(width, height, dataRange, tickColor, annotationColor, monthIndex) {
    let annotations: Annotation[] = [];

    const scaling = 360 / 366;
    const r = 1
    const degToR = (Math.PI / 180)
    const paperAspectRatio = height / width
    const fontSize = height * 0.04
    for (let i = 0; i < (months_short.length); i++) {

        const theta = (cumulDays[i] + 15) * scaling
        const x = (r * Math.cos(theta * degToR) / 2 * paperAspectRatio) + 0.5
        const y = (r * Math.sin(theta * degToR) / 2 + 0.5)
        const newAnnotation = {
            x: x,
            y: y,
            xref: 'x domain',
            yref: 'y domain',
            showarrow: false,
            text: months_short[i],
            font: {
                color: (monthIndex === i) ? "black" : annotationColor,
                weight: 1000,
                size: fontSize,
            },
        }
        annotations.push(newAnnotation)
    }

    const polarLayout = {
        polar: {
            barmode: "stack",
            bgcolor: 'rgba(0, 0, 0, 0)',

            radialaxis: {
                showgrid: true,
                fixedrange: true,
                visible: true,
                range: [dataRange.dataMinPlot, dataRange.dataMaxPlot],
                layer: "above traces",
                tickmode: 'array',
                tickfont: {
                    color: tickColor,
                    weight: 1000,
                    size: fontSize,
                },
                angle: 37,
            },
            angularaxis: {
                showgrid: false,
                fixedrange: true,
                visible: false,
            },
        },
        width: `${width}`,
        height: `${height}`,
        showlegend: false,
        margin: {
            b: 0,
            l: 0,
            r: 0,
            t: 0,
        },
        font: {
            family: 'Lato, sans-serif',
        },
        hoverlabel: {
            font: {
                family: 'Lato, sans-serif',
            }
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        dragmode: false,
        annotations: annotations,
    }

    return polarLayout
}

function generateWindLayout(width, height, tickColor, annotationColor) {
    let annotations: Annotation[] = [];

    const directions = ['North', ' ', 'N-E', ' ', 'East', ' ', 'S-E', ' ', 'South', ' ', 'S-W', ' ', 'West', ' ', 'N-W', ' ']

    const r = 1
    const degToR = (Math.PI / 180)
    const paperAspectRatio = height / width
    const fontSize = height * 0.04

    const angle = 360 / directions.length
    for (let i = 0; i < (directions.length as number); i++) {

        const theta = (90 - angle * i)
        const x = (r * Math.cos(theta * degToR) / 2 * paperAspectRatio) + 0.5
        const y = (r * Math.sin(theta * degToR) / 2 + 0.5)
        const newAnnotation = {
            x: x,
            y: y,
            xref: 'x domain',
            yref: 'y domain',
            showarrow: false,
            text: directions[i],
            font: {
                color: annotationColor,
                weight: 1000,
                size: fontSize,
            },
        }
        annotations.push(newAnnotation)
    }

    const polarLayout = {
        polar: {
            bgcolor: 'rgba(0, 0, 0, 0)',
            barmode: "stack",

            radialaxis: {
                showgrid: true,
                fixedrange: true,
                visible: true,
                // range: [0, data['meta']['max']],
                layer: "above traces",
                tickmode: 'array',
                tickfont: {
                    color: tickColor,
                    weight: 1000,
                    size: fontSize,
                },
                angle: 22.5,
            },
            angularaxis: {
                showgrid: false,
                fixedrange: true,
                visible: false,
                direction: "clockwise",
            },
        },
        width: `${width}`,
        height: `${height}`,
        showlegend: false,
        margin: {
            b: 1,
            l: 1,
            r: 1,
            t: 1,
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        dragmode: false,
        annotations: annotations,
    }

    return polarLayout
}

function generateWindData(previousData, currentData, show) {
    let dataToPlot: Record<string, any>[] = [];

    // const directions8 = ['North', 'N-E', 'East', 'S-E', 'South', 'S-W', 'West', 'N-W']
    const directions16full = ['North', 'NNE', 'North-East', 'ENE', 'East', 'ESE', 'South East', 'SSE', 'South',
        'SSW', 'South West', 'WSW', 'West', 'WNW', 'North West', 'NNW']
    // const directions16 = ['North', ' ', 'N-E', ' ', 'East', ' ', 'S-E', ' ', 'South', ' ', 'S-W', ' ', 'West', ' ', 'N-W', ' ']
    // const directions = (currentData['angle_bins'].length === 8) ? directions8 : directions16
    // const interval = 360 / currentData['angle_bins'].length;
    // const directions = directions16
    const interval = 22.5

    const angles = Array.from({ length: Math.floor(360 / interval) + 1 }, (_, i) => i * interval);

    // const angles = Array.from({ length: Math.floor(360 / interval / 2) + 1 }, (_, i) => i * interval);
    // const angles2 = angles.map((angle) => angle + 180)

    if ((previousData['cutoff_labels'] !== null) && (show[0])) {
        const cutoff_labels = previousData['cutoff_labels']
        const length = previousData['anglePcs_at_speed1'].length
        const data1 = [{
            r: previousData['anglePcs_at_speed1'],
            theta: angles,
            name: "",
            marker: { color: windColors[0] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[0]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: previousData['anglePcs_at_speed2'],
            theta: angles,
            name: "",
            marker: { color: windColors[1] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[1]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: previousData['anglePcs_at_speed3'],
            theta: angles,
            name: "",
            marker: { color: windColors[2] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[2]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: previousData['anglePcs_at_speed4'],
            theta: angles,
            name: "",
            marker: { color: windColors[3] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[3]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }]
        dataToPlot = dataToPlot.concat(data1)
    }

    if ((currentData['cutoff_labels'] !== null) && (show[1])) {
        const cutoff_labels = currentData['cutoff_labels']
        const length = currentData['anglePcs_at_speed1'].length
        const data2 = [{
            r: currentData['anglePcs_at_speed1'],
            theta: angles,
            name: "",
            marker: { color: windColors2[0] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[0]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: currentData['anglePcs_at_speed2'],
            theta: angles,
            name: "",
            marker: { color: windColors2[1] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[1]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: currentData['anglePcs_at_speed3'],
            theta: angles,
            name: "",
            marker: { color: windColors2[2] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[2]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: currentData['anglePcs_at_speed4'],
            theta: angles,
            name: "",
            marker: { color: windColors2[3] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[3]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }]
        dataToPlot = dataToPlot.concat(data2)
    }

    return dataToPlot
}

export { generatePolarData, generatePolarLayout, getDataMinMax, getStripeColor, truncateString, generateWindLayout, generateWindData }