import React, { useState, useEffect } from 'react'
import CarouselContainer from './CarouselContainer.tsx';

const Outputs = () => {
  console.log("RENDERING Outputs")


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CarouselContainer windowSize={windowSize} />
  )
}

export default Outputs