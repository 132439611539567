import TemperaturePopup from './TemperaturePopup.tsx'
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { Marker } from 'react-leaflet';
import { RootState } from '../reducers/store.ts';
import React, { useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import L from 'leaflet';
import useUpdatePositionAndData from './useUpdatePositionAndData.ts';
import MyTooltip from './MyTooltip.tsx';

const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

var blueIcon = new L.Icon({
    iconUrl: '/marker-icon-2x-c3sblue.png',
    shadowUrl: '/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    // className: 'c3sBlueMarker',
});

var redIcon = new L.Icon({
    iconUrl: '/marker-icon-2x-c3sred.png',
    shadowUrl: '/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    // className: 'c3sRedMarker',
});

function MyMarker({ routeNumber, comparisonMode }) {
    useEffect(() => {
        console.log("Rendered MyMarker")
    })

    const variableKey = 'tempAnn'
    // const variableKey = 'tempDaily'
    // const variableKey = 'precipMonthly'

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    // const currentOrPrevious = current ? 'currentData' : 'previousData'

    const averageTempRaw = useTypedSelector(state => state.data[variableKey][routeNumber].data.values)
    // const averageTempRaw = [0, 1, 2, 3]
    const averageTemp = ((averageTempRaw === null) || (averageTempRaw === undefined)) ? "Calculating..." : `${average(averageTempRaw).toFixed(1)} °C`;

    const x = undefined
    console.log("MATT TEST HERE", x, x === null, x === undefined)

    // const position = useTypedSelector(state => state.location.locationPosition)
    // const placeName = useTypedSelector(state => state.location.locationName)
    const position = useTypedSelector(state => state.data[variableKey][routeNumber].metadata.locationPosition)
    const placeName = useTypedSelector(state => state.data[variableKey][routeNumber].metadata.locationName)
    // const routeNumber = useTypedSelector(state => state.data.routeNumber)
    // console.log("DEBUG MyMarker", position, placeName, routeNumber)

    // console.log("MyMarker", current, position, placeName, averageTemp)

    const clickOrTap = isMobile ? "Tap" : "Click or drag"

    // const markerRefs = useRef<(React.RefObject<MarkerType> | null)[]>([]);

    // const [showTooltip, setShowTooltip] = useState(true);

    const { updatePositionAndData } = useUpdatePositionAndData();

    // useEffect(() => {
    //     if (isMobile) {
    //         const tooltipTimeout = setTimeout(() => {
    //             setShowTooltip(false);
    //         }, 3000);

    //         return () => {
    //             clearTimeout(tooltipTimeout);
    //         };
    //     }
    // }, []);

    // const sequenceAbortController = useSequenceAbortController()[routeNumber].current

    const eventHandlers = useMemo(
        () => ({
            dragend(e) {
                const newLatLng = e.target.getLatLng()
                const lat = newLatLng.lat
                const lng = newLatLng.lng;
                updatePositionAndData(lat, lng, routeNumber, null, 'dragged')
                console.log("dragend newLatLng", newLatLng)
            },
        }),
        [updatePositionAndData, routeNumber],
    )

    // const markerRef = useRef<L.Marker[] | null[]>([null, null, null]);
    // // Open popup programmatically when position changes
    // useEffect(() => {
    //     for (let i = 0; i < markerRef.length; i++) {
    //         if (markerRef[i]) {
    //             if (markerRef[i].current && position) {
    //                 // Get the Leaflet marker instance
    //                 const marker = markerRef[i].current;
    //                 // Set the marker position (if it's different from the current)
    //                 // marker.setLatLng(position);
    //                 // Open the popup
    //                 marker.openPopup();
    //             }
    //         }
    //     }
    // }, [position]);


    if (position === null) {
        return null
    }

    if (placeName === null) {
        return null
    }


    let icon, content, locked
    if (routeNumber === 0) {
        icon = redIcon
        content = comparisonMode ? "Location currently locked" : `${clickOrTap} somewhere</br>to change this location`
        locked = comparisonMode
    } else {
        icon = blueIcon
        content = `${clickOrTap} somewhere</br>to change this location`
        locked = false
    }

    // const icon = current ? blueIcon : redIcon
    // // WHy is this wrong sometimes?
    // const content = current ? `${clickOrTap} or drag somewhere</br>to change this location` : "Location currently locked"
    // console.log("DEBUG", current, content, icon.options.iconUrl)

    // Calculate markers at intervals of 360 degrees longitude
    let markerPositions = [position]
    for (let i = -1; i <= 1; i += 2) {
        const newPosition = {
            lat: position.lat,
            lng: position.lng + i * 360
        }
        markerPositions.push(newPosition);
    }

    const draggable = (!locked && !isMobile)

    return (
        <>
            {markerPositions.length > 0 && markerPositions.map((pos, index) => (
                <Marker
                    position={pos}
                    key={index}
                    icon={icon}
                    riseOnHover={true}
                    draggable={draggable}
                    eventHandlers={eventHandlers}

                // ref={markerRef[index]}
                >
                    {/* This is a horrendous hack, but this is here to force a rerender of the tooltips
                        AND the popup border stuff (via the class name)
                        which SHOULD be happening automatically, but isn't. */}
                    <div key={comparisonMode} >
                        <TemperaturePopup
                            position={pos}
                            placeName={placeName}
                            averageTemp={averageTemp}
                            locked={locked}
                        />

                        <MyTooltip content={content} />
                    </div>
                </Marker>
            ))}
        </>
    );
}

export default MyMarker