import React, { useEffect, memo } from 'react';
import { ActionIcon, Loader, Tooltip, Stack } from '@mantine/core';
import { Popup } from 'react-leaflet';
import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { RootState } from '../reducers/store.ts';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux'

import { switchCurrentAndPrevious } from '../reducers/dataSlice.ts'
import { switchCurrentAndPreviousLocation } from '../reducers/locationSlice.ts'
import ToggleGraphs from './ToggleGraphs.tsx';

function calculateArea(lat: number) {
  const dtor = Math.PI / 180
  const dlon_dlon_r2 = 772.7646
  return Math.cos(lat * dtor) * dlon_dlon_r2
}

function addDegreeSymbol(text) {
  return text.replace(/([NESW])/g, '°$1');
}

function splitAtComma(text) {
  if (/^[0-9]/.test(text)) {
    // Is a place like 56N, 45E
    const latLonFormatted = addDegreeSymbol(text)
    return [latLonFormatted]
  } else {
    let parts = text.split(',', 2);
    return parts.map(part => part.trim());
  }
}

function formatLatitude(lat: number): string {
  if (lat > 0) {
    return `${lat.toFixed(2)} °N`
  } else {
    return `${Math.abs(lat).toFixed(2)} °S`
  }
}

function formatLongitude(lng: number): string {
  if (lng > 0) {
    return `${lng.toFixed(2)} °E`
  } else {
    return `${Math.abs(lng).toFixed(2)} °W`
  }
}

function TemperaturePopup({ position, placeName, averageTemp, locked }) {
  useEffect(() => {
    console.log("Rendered TemperaturePopup")
  })

  const titleSplit = splitAtComma(placeName)

  const marginBottom = (titleSplit.length > 1) ? "0rem" : "0.5rem";

  const lockSize = 40

  const dispatch = useDispatch()

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const fetchingData = useTypedSelector(state => state.data.fetchingData)
  const comparisonMode = useTypedSelector(state => state.data.comparisonMode)

  // const gotSecondData = useTypedSelector(state => state.data.gotCompleteData2)
  const gotSecondData = true

  const color = locked ? "c3sRed" : "c3sBlue"

  // const [test, setTest] = useState(false)
  // const [test2, setTest2] = useState(false)

  const fetchingAnyData = fetchingData.some(fetching => fetching)
  console.log("fetchingAnyData", fetchingAnyData, fetchingData)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    if (fetchingAnyData) {
      return
    }

    // setTest(prev => !prev)
    // setTest2(prev => !prev)

    dispatch(switchCurrentAndPrevious())
    dispatch(switchCurrentAndPreviousLocation())
  }

  const tooltipLabel = () => {
    return (
      <div style={{ display: 'flex', textAlign: 'center' }}>
        {locked ? "Unlock this location" : "Lock this location"}
        <br></br>
        {locked ? "(and lock the other)" : "(and unlock the other)"}
      </div>
    )
  }

  // const loaderColor = (locked || !comparisonMode) ? 'c3sRed' : 'c3sBlue'

  return (
    <Popup
      minWidth={250}
      autoPan={false}
      className={(locked || !comparisonMode) ? 'redPopup' : 'bluePopup'}
    >
      <div style={{
        marginTop: "10px",
        marginBottom: "10px",
        // fontSize: "0.9rem",
      }}>
        <h3 style={{ marginBottom: marginBottom }}>
          {titleSplit[0]}
        </h3>
        {titleSplit.length > 1 && (
          <h4 style={{ marginTop: 0 }}>
            <i>{titleSplit[1]}</i><br />
          </h4>
        )}
        Latitude: <b>{formatLatitude(position.lat)}</b><br />
        Longitude: <b>{formatLongitude(position.lng)}</b><br />
        Area represented: <b>{calculateArea(position.lat).toFixed(0)} km<sup>2</sup></b><br />
        Mean temperature: <b>{averageTemp}</b>

        <div style={{
          position: "absolute",
          bottom: "0.5rem",
          right: "0.5rem",
        }}>

          <Stack gap="5px">
            <ToggleGraphs buttonWidth={lockSize} />

            {(comparisonMode) ?
              (fetchingAnyData) ?
                <Loader color={color} size={lockSize}/>
                :
                (gotSecondData ?
                  <Tooltip multiline={true} label={tooltipLabel()} >
                    <ActionIcon
                      size={lockSize}
                      color={color}
                      onClick={handleClick}
                    >
                      {locked ? <IconLock /> : <IconLockOpen />}
                    </ActionIcon>
                  </Tooltip>
                  : null)
              : null
            }
          </Stack>
        </div>
      </div>
    </Popup>
  );
};

export default memo(TemperaturePopup);
