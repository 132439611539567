import React, { memo, useEffect } from 'react';
import { ActionIcon, Tooltip, Group, Modal, Title, Space, List, Accordion, Text } from '@mantine/core';
import {
    IconQuestionMark, IconTemperaturePlus, IconWind, IconUsers, IconWorldHeart,
    IconUmbrella, IconSnowflake, IconDroplets,
    IconSteeringWheel, IconAlertTriangle, IconPizza,
} from '@tabler/icons-react';
import { isMobile } from 'react-device-detect';
import { useDisclosure } from '@mantine/hooks';
import { usePreventPullToRefreshButton } from './usePreventPullToRefresh.ts';
import { getECMWFText, getBonusText, getDisclaimerText } from './variableDescriptions.ts';
import { TRANSLUCENT_COLOR } from './constants.ts';

interface AccordionLabelProps {
    label: string;
    image: React.ReactNode;
    description: string;
}

const lineHeight = "100%"
const myStyle = { lineHeight: lineHeight, marginBottom: "0.5em" }

function AccordionLabel({ label, image, description }: AccordionLabelProps) {
    return (
        <Group wrap="nowrap">
            {image}
            <div>
                <Text style={(!isMobile && (description !== '')) ? myStyle : {}}>{label}</Text>
                {!isMobile &&
                    <Text style={{ lineHeight: "100%" }} size="md" c="dimmed" fw={400}>
                        <i>{description}</i>
                    </Text>
                }
            </div>
        </Group>
    );
}

function ShowAbout({ buttonWidth }) {
    const [opened, { open, close }] = useDisclosure(false);

    const titleButtonWidth = 35
    const tooltipLabel = 'Help and info'

    // const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    // const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)  // T or P layer
    const spectralPalette = true

    const buttonRef = usePreventPullToRefreshButton()

    const title = (
        <Group>
            <ActionIcon
                size={titleButtonWidth}
                ref={buttonRef}
                onClick={handleClose}
            >
                <IconQuestionMark style={{ width: '70%', height: '70%' }} stroke={2} />
            </ActionIcon>

            <Title order={3}>
                About the <b>ERA explorer</b>
            </Title>
        </Group>
    )

    function handleClose() {
        close()
        writeToStorage()
    }

    function writeToStorage() {
        localStorage.setItem('aboutViewed', 'true')
    }

    // Update the local storage when the component is mounted
    useEffect(() => {
        writeToStorage()
    }, [])

    const isMobileMod = false

    const locations = [
        {
            icon: <IconTemperaturePlus />,
            lat: 36.17,
            lng: -115.15,
            plot: 1,
            question: (isMobileMod ? "When is it hot in <b>Las Vegas</b>?" : "What's the hottest month in <b>Las Vegas</b>?"),
        },
        {
            icon: <IconUmbrella />,
            lat: 19.08,
            lng: 72.89,
            plot: 0,
            question: (isMobileMod ? "When does it rain in <b>Mumbai</b>?" : "When is the rainy season in <b>Mumbai</b>?"),
        },
        {
            icon: <IconWind />,
            lat: 62.01,
            lng: -6.77,
            plot: 6,
            question: (isMobileMod ? "Which way is the wind in <b>Tórshavn</b>?" : "Which way does the wind usually blow in <b>Tórshavn</b>?"),
        },
        {
            icon: <IconDroplets style={{ scale: '1' }} />,
            lat: 14.69,
            lng: -17.45,
            plot: 3,
            question: "How has annual rainfall changed in <b>Dakar</b>?",
        },
        {
            icon: <IconSnowflake />,
            lat: 46.20,
            lng: 6.15,
            plot: 2,
            question: "Does <b>Geneva</b> often have frost in January?",
        },
        {
            icon: <IconPizza />,
            lat: 40.84,
            lng: 14.25,
            plot: 5,
            question: "What are the warming stripes for <b>Naples</b>?",
        },
    ];

    const clickOrTap = isMobile ? 'Tap' : 'Click'
    const joiningWord = isMobile ? '' : 'on'
    const maxElements = isMobile ? 3 : 10

    const accordionInfo = [
        {
            id: 'ecmwf',
            image: <IconUsers />,
            label: "About ECMWF",
            description: 'Who we are and what we do',
            content: getECMWFText(),
        },
        {
            id: 'era5',
            image: <IconWorldHeart />,
            label: 'About this data',
            description: 'Spatially complete reanalysis from 1940',
            content: getBonusText(),
        },
        {
            id: 'disclaimer',
            image: <IconAlertTriangle />,
            label: 'Disclaimer',
            description: '',
            content: getDisclaimerText(),
        },
    ];

    const items = accordionInfo.map((item) => (
        <Accordion.Item value={item.id} key={item.label} className='fullWidth'>
            <Accordion.Control>
                <AccordionLabel {...item} />
            </Accordion.Control>
            <Accordion.Panel>
                <Text size="md" dangerouslySetInnerHTML={{ __html: item.content }} />
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <>
            <Tooltip label={tooltipLabel}>
                <ActionIcon
                    size={buttonWidth}
                    onClick={open}
                    ref={buttonRef}
                    color={spectralPalette ? TRANSLUCENT_COLOR : 'c3sGrey'}
                >
                    <IconQuestionMark style={{ width: '70%', height: '70%' }} stroke={2} />
                </ActionIcon>
            </Tooltip>

            <Modal
                opened={opened}
                onClose={handleClose}
                title={title}
                centered
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
            >

                {!isMobile &&
                    <>
                        Use the <b>ERA explorer</b> to explore historical <b>climate</b> data from anywhere on earth!

                        <Space h="md" />
                    </>
                }

                <Accordion chevronPosition="right" variant="contained" defaultValue="0" data-autofocus>
                    <Accordion.Item value={"0"} key={0} className='fullWidth'>
                        <Accordion.Control>
                            <AccordionLabel label={'How to use this app'} image={<IconSteeringWheel />} description='Unlock the power of ERA!' />
                        </Accordion.Control>
                        <Accordion.Panel>

                            {isMobile ?
                                <>
                                    <b>ERA explorer</b> calculates climate info on the go! <b>{clickOrTap}</b> {joiningWord} a question to begin...
                                </>
                                :
                                <>
                                    <b>ERA explorer</b> accesses the vast ERA archive to calculate useful climate information on the go! <b>{clickOrTap}</b> {joiningWord} a question to begin...
                                </>
                            }
                            <Space h="xs" />

                            {/* This div is a hack to stop the list overflowing (which it shouldn't do, but does). This also triggers a horizontal scroll bar */}
                            <div style={{ maxWidth: "98%" }}>
                                <List>
                                    {locations.slice(0, maxElements).map((location, index) => (
                                        <List.Item key={index} icon={location.icon}>
                                            <a href={`?lat=${location.lat}&lng=${location.lng}&plot=${location.plot}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: `<i>${location.question}</i>`
                                                }} className='aboutLinks' />
                                        </List.Item>
                                    ))}
                                </List>
                            </div>
                        </Accordion.Panel>
                    </Accordion.Item>

                    {items}

                </Accordion>
            </Modal>
        </>
    );
}

export default memo(ShowAbout);
