import { createSlice } from '@reduxjs/toolkit'

// Define the state type
interface GraphState {
  graphsVisible: boolean;
  attentionReceived: boolean;
  graphWiggle: boolean;
  spectralPalette: boolean;
  windEnabled: boolean;
  climatologyIndex: number;
  climatologyView: boolean;
  multipanel: boolean;
}

const initialState: GraphState = {
  graphsVisible: false,
  attentionReceived: false,
  graphWiggle: false,
  spectralPalette: true,
  windEnabled: true,
  climatologyIndex: 0,
  climatologyView: false,
  multipanel: false,
}

export const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    toggleGraphs: state => {
      state.graphsVisible = !state.graphsVisible
    },
    closeGraphs: state => {
      state.graphsVisible = false
    },
    openGraphs: state => {
      state.graphsVisible = true
    },
    setAttentionReceived: state => {
      state.attentionReceived = true
    },
    wiggleGraphs: state => {
      state.graphWiggle = true
    },
    resetWiggle: state => {
      state.graphWiggle = false
    },
    toggleSpectralPalette: state => {
      state.spectralPalette = !state.spectralPalette
    },
    toggleWind: state => {
      state.windEnabled = !state.windEnabled
    },
    updateClimatologyIndex: (state, action) => {
      state.climatologyIndex = action.payload
    },
    toggleClimatologyView: state => {
      state.climatologyView = !state.climatologyView
    },
    toggleMultipanel: state => {
      state.multipanel = !state.multipanel
      state.graphsVisible = true
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  toggleGraphs, setAttentionReceived,
  closeGraphs, openGraphs, wiggleGraphs,
  resetWiggle, toggleSpectralPalette,
  updateClimatologyIndex, toggleClimatologyView,
  toggleMultipanel, toggleWind
} = graphSlice.actions

export default graphSlice.reducer