import { useEffect, useState } from 'react';

const useFrameRate = () => {
  const sampleTime = 300

  const [fps, setFps] = useState(0);

  useEffect(() => {
    let lastTime = performance.now();
    let frames = 0;
    let animationFrameId;

    const loop = () => {
      const currentTime = performance.now();
      frames++;
      const deltaTime = currentTime - lastTime;

      if (deltaTime >= sampleTime) { // 1 second
        setFps(1000 * frames / deltaTime);
        frames = 0;
        lastTime = currentTime;
      }

      animationFrameId = requestAnimationFrame(loop);
    };

    loop();

    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return fps;
};

export default useFrameRate;