import { useCallback } from 'react';
import fetchZarrData from './fetchZarrData.ts'
import { useDispatch } from 'react-redux'
import {
    updateVariable,
    // updateTempMonthly,
    // updateWindAnn,
    // updateTempAnn,
    // updatePrecipAnn,
    // updateTempDaily,
    // updatePrecipHourly,
} from '../reducers/dataSlice.ts'
import { variableOptions } from './constants.ts';
// import store from '../reducers/store.ts';

const useFetchData = () => {
    const dispatch = useDispatch();

    // const talkingToOSM = useSelector(state => state.location.talkingToOSM)
    // const locationPosition = useSelector(state => state.location.locationPosition)

    const fetchData = useCallback((lat: number, lng: number, routeNumber: number, route: string, debug: string, abortController: AbortController): Promise<void> => {
        // We move the current data to previous data, if it exists.
        // Then we set the current data locationPosition to the lat/lon.
        // This means that if we get the locationName AFTER the data (maybe on a slow connection) then we can still get the data and just update the
        // locationName if the positions still match when the locationName is finally returned
        console.log(`fetchData: Called by ${debug}`, route, routeNumber);
        return new Promise<void>((resolve, reject) => {

            const query = `${route}?x=${lng}&y=${lat}&r=${routeNumber}`;

            fetchZarrData(query, abortController.signal)
                .then((result) => {
                    // dispatch(setLocationNameCurrentData())

                    // Get the current value of the store, not stateful
                    // This should be whatever we last clicked on.
                    // If we've clicked since then the update below won't happen because of the abortController
                    // EXCEPT this isn't always the case, perhaps due to the sequential get controller being locked sometimes.
                    // Also, if the place name is updated SLOWER than the data then this will be wrong
                    // const locationName = store.getState().location.locationName;  
                    // const locationPosition = store.getState().location.locationPosition;
                    // result.locationName = locationName

                    // This will happen if we receive the data after having clicked away
                    // I'm not sure if it is needed anymore though...
                    // const tol = 0.001
                    // if ((Math.abs(locationPosition.lat - lat) > tol) || (Math.abs(locationPosition.lng - lng) > tol)) {
                    //     console.log("Error: Mismatched positions before and after request", locationPosition.lat, lat)
                    //     resolve(); // Resolve the promise when the operation is complete
                    //     return
                    // }

                    const matchedVariables = variableOptions
                        .filter(option => option.route === route) // Filter to get all matching options
                        .map(option => option.sliceKey);          // Extract the sliceKey from each matched option

                    matchedVariables.forEach(sliceKey => (
                        dispatch(
                            updateVariable({
                                sliceKey: sliceKey,
                                result: result
                            })
                        )
                        ))

                    // switch (route) {
                    //     case "precip-monthly-clim":
                    //         const matchedVariables = variableOptions
                    //             .filter(option => option.route === route) // Filter to get all matching options
                    //             .map(option => option.sliceKey);          // Extract the sliceKey from each matched option
                    //         matchedVariables.forEach(variable =>
                    //             dispatch(updateVariable({ variable: variable, result: result })))
                    //         break;
                    //     // case "temperature-monthly-clim":
                    //     //     dispatch(updateTempMonthly(result));
                    //     //     break;
                    //     // case "wind-annual-clim":
                    //     //     dispatch(updateWindAnn(result));
                    //     //     break;
                    //     // case "temperature-annual-timeseries":
                    //     //     dispatch(updateTempAnn(result));
                    //     //     break;
                    //     // case "precip-annual-timeseries":
                    //     //     dispatch(updatePrecipAnn(result));
                    //     //     break;
                    //     // case "temperature-daily-clim":
                    //     //     dispatch(updateTempDaily(result));
                    //     //     break;
                    //     // case "precip-hourly-hist":
                    //     //     dispatch(updatePrecipHourly(result));
                    //     //     break;
                    //     default:
                    //         return reject(new Error());
                    // }
                    resolve(); // Resolve the promise when the operation is complete
                })
                .catch((error) => {
                    reject(error); // Reject the promise if there's an error
                });
        });
    }, [dispatch]);

    return { fetchData };
};

export default useFetchData;
