import React, { useState, memo, useEffect } from 'react';
import Plot from "react-plotly.js";
import { generatePolarData, generatePolarLayout, getStripeColor, getDataMinMax, truncateString, generateWindLayout, generateWindData } from './graphHelpers.ts'
import { frostColor, frostColor2, summerColor, summerColor2, tropicalColor, tropicalColor2, variableOptions } from './constants.ts';
import store from '../reducers/store.ts';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../reducers/store.ts';
import { useMantineTheme } from '@mantine/core';

function makePrecipText(binEdges, forTicks) {
    const suffix = forTicks ? "mm" : "mm/hour"
    const breakOrSpace = forTicks ? "<br>" : " "
    const text = binEdges.slice(0, -1).map((X, i) => {
        let X1 = Number(X.toFixed(2));              // Truncate X to 2 significant figures
        let X2 = Number(binEdges[i + 1].toFixed(2)); // Truncate the next value to 2 significant figures
        if (i === 0) {
            X1 = 0
        }
        if (i === 0) {
            return `Up to${breakOrSpace}${X2} ${suffix}`;
        } else if (i === binEdges.length - 2) {
            return `${X1}${suffix}${breakOrSpace}or more`;
        } else {
            return `${X1} to${breakOrSpace}${X2} ${suffix}`;
        }
    });
    return text;
}

// Function to format numbers to 2 significant figures
function formatPrecipValues(value) {
    if (value < 1) {
      return value.toPrecision(1);  // For numbers < 1, use toPrecision
    } else if (value > 1000) {
      return parseFloat(value.toPrecision(3)); // For numbers >= 1000, remove trailing zeros
    } else {
        return parseFloat(value.toPrecision(2)); // For numbers >= 1, remove trailing zeros
      }
  }

interface Annotation {
    x: number;
    y: number;
    xref: string;
    yref: string;
    showarrow: boolean;
    text: string;
    font: {
        color: string;
        weight: number;
        size: number;
    };
}

function makeAnnotation(text: string, y: number, fontSize: number) {
    const newAnnotation = {
        x: 0.5,
        y: y,
        xref: 'x domain',
        yref: 'y domain',
        showarrow: false,
        text: truncateString(text, false),
        yanchor: 'middle',
        font: {
            color: "#00000055",
            weight: 1000,
            size: fontSize * 2,
        },
    }
    return newAnnotation;
}

function MakeGraph({ width, height, variable, colors, showLocations, showLines, landscape }) {
    useEffect(() => {
        console.log("Rendered MakeGraph")
    })
    const [isMounted, setIsMounted] = useState(false);

    const theme = useMantineTheme();

    useEffect(() => {
        // Set the component as mounted when the effect runs
        setIsMounted(true);

        return () => {
            // Cleanup function: Set the component as unmounted
            setIsMounted(false);
        };
    }, []);

    // To trigger a re-render when the popup ordering is switched, so the badges and plots remain in sync
    // Similarly for the comparison mode
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    // const popupsInverted = useTypedSelector(state => state.data.popupsInverted)
    const comparisonMode = useTypedSelector(state => state.data.comparisonMode)

    const climatologyView = useTypedSelector(state => state.graph.climatologyView)
    const climatologyIndex = useTypedSelector(state => state.graph.climatologyIndex)
    const monthIndex = climatologyView ? climatologyIndex : null

    const aggregation = variableOptions.find((element) => element.name === variable)?.aggregation;
    let climatologyHighlight = (((monthIndex !== null) && (aggregation === "monthly")) ? [{
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: monthIndex + 1.5,  // Start of the rectangle on x-axis
        x1: monthIndex + 2.5,  // End of the rectangle on x-axis
        y0: 0,  // Start of the rectangle on the y-axis (paper refers to full plot height)
        y1: 1,  // Full height of the plot area
        // fillcolor: theme.colors.c3sYellow[9] + '44',
        fillcolor: theme.colors.c3sRed[9] + '22',
        line: {
            width: 0  // No border line around the rectangle
        },
        layer: 'below'  // Ensure the rectangle is behind the bars
    }] : null)
    console.log("TESTING", variable, climatologyHighlight)

    // const paper_bgcolor = '#eeeeee'
    const paper_bgcolor = 'transparent'

    let yvals: number[] | null = null
    let yvals2: number[] | null = null
    let yvals_b: number[] | null = null
    let yvals_c: number[] | null = null
    let yvals2_b: number[] | null = null
    let yvals2_c: number[] | null = null
    let base2: number[] | null = null

    const months_short = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const months_long = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    let markerColor = colors[0]
    let markerColor2 = colors[1]
    let markerColor_b: string | null = null
    let markerColor_c: string | null = null
    let markerColor2_b: string | null = null
    let markerColor2_c: string | null = null
    // let markerColorAlt = markerColor
    // let markerColorAlt2 = markerColor2

    let plotType, xvals, plotMode, xvals2
    let xAxisRange: (number | null)[] | null = null;
    // let yAxisRange: [number, null] | null = null
    let base: number[] | null = null
    let customdata: number[] | number[][] | null = null
    let customdata2: number[] | null = null
    let dtick: number | null = null
    let bargap: number | null = null
    let barcornerradius: number = (showLocations[0] !== showLocations[1]) ? 10 : 7
    let tickmode: string = "linear"
    let yAxisVisible = true
    let tickangle = -60
    // const monthIndex = new Date().getMonth()
    let text: string[] = [""]
    let hovertemplate: string = ""
    let hovertext: string[] | null = null
    let hovertemplate2: string = ""
    let hovertext2: string[] | null = null
    let isPolar = false
    let barmode = ""
    let annotations: Annotation[] = [];
    let tickposition = landscape ? 0.08 : 0.1
    let xAxisTickposition = 0
    let ticklabelposition = "outside top"
    let include: number[] | number | null = null
    let minallowed: number | null = null
    let markerOutlineWidth: number = 0
    let markerOutlineWidth2: number = 0
    let lineWidth: number = 3
    let markerSize = 15
    let markerSize2 = 13
    let barWidth: number | null = null
    let barOffset1: number | null = null
    const fontSize = height * 0.04 * 1.2
    // const tickColor = "#00000066"
    const tickColor = "#000000"
    const annotationColor = "00000099"
    const xShift = -2
    let markerSymbol = "x"
    let markerSymbol2 = "circle"
    let ticktext = months_short
    let xAxisTickSize = 12

    xvals2 = null

    const sliceKey = variableOptions.find((element) => element.name === variable)!.sliceKey;
    // const previousData = store.getState().data[sliceKey][1].data
    // const currentData = store.getState().data[sliceKey][0].data
    const previousData = useTypedSelector(state => state.data[sliceKey][1].data)
    const currentData = useTypedSelector(state => state.data[sliceKey][0].data)
    
    switch (variable) {
        case "Wind annual climatology":
            isPolar = true
            break
        case "Temperature daily climatology":
        case "Precip monthly climatology":
        case "Temperature monthly climatology":
            plotType = "bar"
            plotMode = "markers"
            tickmode = "array"

            if (previousData['time'] !== null) {
                xvals = previousData['time'].map(x => x - xShift)
            } else if (currentData['time'] !== null) {
                xvals = currentData['time'].map(x => x - xShift)
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            yvals = previousData['diff']
            yvals2 = currentData['diff']
            base = previousData['min']
            base2 = currentData['min']

            markerColor = Array(12).fill(markerColor)
            markerColor2 = Array(12).fill(markerColor2)
            text = months_long

            if (variable === "Temperature monthly climatology") {
                hovertemplate = '%{text}<br><b>Max: </b>%{y:.1f} °C<br><b>Min</b>: %{base:.1f} °C'
            } else if (variable === "Precip monthly climatology") {
                if (yvals !== null) {
                    hovertext = yvals.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                if (yvals2 !== null) {
                    hovertext2 = yvals2.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                hovertemplate = '%{hovertext}'  // Refer to hovertext in template
                hovertemplate2 = '%{hovertext}'  // Refer to hovertext in template
                
                base = null
                base2 = null
            } else if (variable === "Temperature daily climatology") {
                isPolar = true
            }
            break
        case "Precip hourly hist":
            plotType = "bar"
            plotMode = "markers"
            tickmode = "array"

            if (previousData['histogram'] !== null) {
                xvals = Array.from({ length: previousData['histogram'].length }, (_, index) => index - xShift);
                text = makePrecipText(previousData['bin_edges'], false)
                ticktext = makePrecipText(previousData['bin_edges'], true)
            } else if (currentData['histogram'] !== null) {
                xvals = Array.from({ length: currentData['histogram'].length }, (_, index) => index - xShift);
                text = makePrecipText(currentData['bin_edges'], false)
                ticktext = makePrecipText(currentData['bin_edges'], true)
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            yvals = previousData['histogram']
            yvals2 = currentData['histogram']

            markerColor = Array(12).fill(markerColor)
            markerColor2 = Array(12).fill(markerColor2)

            // hovertemplate = '%{text}<br><b>Total</b>: %{y:.1f} %'
            if (yvals !== null) {
                hovertext = yvals.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} %`)  // Apply custom formatting
            }
            if (yvals2 !== null) {
                hovertext2 = yvals2.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} %`)  // Apply custom formatting
            }
            hovertemplate = '%{hovertext}'  // Refer to hovertext in template
            hovertemplate2 = '%{hovertext}'  // Refer to hovertext in template

            base = null
            base2 = null
            tickangle = 0
            xAxisTickSize = width * 0.035
            tickposition *= 1.2

            break
        case "Frost days etc":
            // barmode = 'stack'
            plotType = "scatter"
            plotMode = "lines+markers"
            tickmode = "array"

            markerColor = frostColor
            markerColor_b = summerColor
            markerColor_c = tropicalColor
            // markerColor = theme.colors.c3sBlue[9]
            // markerColor_b = theme.colors.c3sYellow[9]
            // markerColor_c = theme.colors.c3sRed[9]

            xAxisRange = [1.5, 1.5 + 12]

            if (previousData['time'] !== null) {
                xvals = previousData['time'].map(x => x - xShift)
            } else if (currentData['time'] !== null) {
                xvals = currentData['time'].map(x => x - xShift)
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }

            if (previousData['time'] !== null) {
                // xvals = previousData['time'].map(x => x - xShift)
                yvals = previousData['frost']
                yvals_b = previousData['summer']
                yvals_c = previousData['tropical']
            }

            if (currentData['time'] !== null) {
                // xvals2 = currentData['time'].map(x => x - xShift)
                yvals2 = currentData['frost']
                yvals2_b = currentData['summer']
                yvals2_c = currentData['tropical']

                markerColor2 = frostColor2
                markerColor2_b = summerColor2
                markerColor2_c = tropicalColor2
                // markerColor2 = theme.colors.c3sBlue[5]
                // markerColor2_b = theme.colors.c3sYellow[6]
                // markerColor2_c = theme.colors.c3sRed[5]
            }
            text = months_long
            barcornerradius = 5
            bargap = 0
            include = 110
            minallowed = -5
            markerOutlineWidth = 2
            markerOutlineWidth2 = 2

            hovertemplate = '%{text}<br><b>Frost days: </b>%{y:.0f} %'
            break
        case "Precip annual timeseries":
        case "Temperature annual timeseries":
            if (variable === "Temperature annual timeseries") {
                plotType = "scatter"
                plotMode = "lines+markers"
                hovertemplate = '<b>Year:</b> %{text}<br><b>Average</b>: %{y:.1f} °C'
            } else if (variable === "Precip annual timeseries") {
                plotType = "bar"
                plotMode = "markers"
                barmode = "overlay"
                // hovertemplate = '<b>Year:</b> %{text}<br><b>Total</b>: %{y:.2s} mm'
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            xAxisRange = [1939, null]
            dtick = 10
            tickangle = -90
            xAxisTickposition = 0

            if (previousData['time'] !== null) {
                xvals = previousData['time']
            } else if (currentData['time'] !== null) {
                xvals = currentData['time']
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            yvals = previousData['values']
            yvals2 = currentData['values']

            text = xvals
            // bargap = 0.5

            if (variable === "Precip annual timeseries") {
                if (yvals !== null) {
                    hovertext = yvals.map((y, i) => `<b>Year:</b> ${xvals[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                if (yvals2 !== null) {
                    hovertext2 = yvals2.map((y, i) => `<b>Year:</b> ${xvals[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                hovertemplate = '%{hovertext}'  // Refer to hovertext in template
                hovertemplate2 = '%{hovertext}'  // Refer to hovertext in template
                barWidth = 0.5
                barOffset1 = -0.7

                tickposition *= 1.5
                // if ((yvals !== null) && (yvals2 !== null) && (showLocations[0]) && (showLocations[1])) {
                //     bargap = 0
                // } else {
                //     bargap = null
                // }
                // bargap = 0.3
            } else if (variable === "Temperature annual timeseries") {
                tickposition *= 1.6
                lineWidth = 2
                markerSize = 7
                markerSize2 = markerSize
                markerSymbol = "circle"
                markerSymbol2 = markerSymbol
            }
            break
        case "Temperature warming stripes":
            barmode = 'stack'
            plotType = "bar"
            dtick = 10

            yvals = previousData['anomalies']
            if (yvals !== null) {
                markerColor = (yvals as number[]).map(
                    function (value) { return getStripeColor(value) }
                )
            }

            yvals2 = currentData['anomalies']
            if (yvals2 !== null) {
                markerColor2 = (yvals2 as number[]).map(
                    function (value) { return getStripeColor(value) }
                )
            }
            bargap = 0
            barcornerradius = 0
            yAxisVisible = false
            tickangle = -90
            xAxisTickposition = 0

            hovertemplate = '<b>Year:</b> %{text}<br><b>Anomaly</b>: %{customdata:.1f} °C'
            if ((previousData['anomalies'] !== null) && (previousData['anomalyScaling'] !== null)) {
                customdata = previousData['anomalies'].map((anomaly: number) => anomaly * previousData['anomalyScaling'])
            }
            if ((currentData['anomalies'] !== null) && (currentData['anomalyScaling'] !== null)) {
                customdata2 = currentData['anomalies'].map((anomaly: number) => anomaly * currentData['anomalyScaling'])
            }

            if (previousData['time'] !== null) {
                xvals = previousData['time']
            } else if (currentData['time'] !== null) {
                xvals = currentData['time']
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            text = xvals
            if (yvals !== null) {
                yvals = Array(xvals.length).fill(1)
            }
            if (yvals2 !== null) {
                yvals2 = Array(xvals.length).fill(1)
            }
            if ((showLocations[0]) && (showLocations[1]) && (customdata !== null) && (customdata2 !== null)) {
                const strangeScaling = 0.96  // Seems necessary as the top of the plot isn't centred on 1 for some reason
                const currentLocationName = store.getState().data.tempAnn[0].metadata.locationName
                const previousLocationName = store.getState().data.tempAnn[1].metadata.locationName
                annotations.push(makeAnnotation(currentLocationName ?? "Loading...", 0.75 * strangeScaling, fontSize))
                annotations.push(makeAnnotation(previousLocationName ?? "Loading...", 0.25 * strangeScaling, fontSize))
            } else {
                include = [-0.5, 1.5]
            }

            break
        default:
            throw new Error()
    }

    if (hovertemplate2 === "") {
        hovertemplate2 = hovertemplate
    }

    let dataToPlot: Record<string, any>[] = [];

    if (showLocations[0] && (yvals !== null)) {
        if (showLines[0]) {
            dataToPlot.push({
                x: xvals,
                y: yvals,
                width: barWidth,
                offset: barOffset1,
                base: base,
                mode: plotMode,
                type: plotType,
                name: "",
                marker: {
                    color: markerColor,
                    size: markerSize,
                    symbol: markerSymbol,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth
                    }
                },
                line: {
                    color: markerColor[0],
                    width: lineWidth,
                    shape: "spline"
                },
                text: text,
                hovertemplate: hovertemplate,
                hovertext: hovertext,
                textposition: "none",
                customdata: customdata,
            })
        }

        if ((yvals_b !== null) && (showLines[1])) {
            const hovertemplate2 = '%{text}<br><b>Summer days: </b>%{y:.0f} %'

            dataToPlot.push({
                x: xvals,
                y: yvals_b,
                mode: plotMode,
                type: plotType,
                name: "",
                marker: {
                    color: markerColor_b,
                    size: markerSize,
                    symbol: markerSymbol,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth
                    }
                },
                line: {
                    color: markerColor_b,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }

        if ((yvals_c !== null) && (showLines[2])) {
            const hovertemplate2 = '%{text}<br><b>Tropical nights: </b>%{y:.0f} %'

            dataToPlot.push({
                x: xvals,
                y: yvals_c,
                mode: plotMode,
                type: plotType,
                name: "",
                marker: {
                    color: markerColor_c,
                    size: markerSize,
                    symbol: markerSymbol,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth
                    }
                },
                line: {
                    color: markerColor_c,
                    width: lineWidth,
                    shape: "spline"
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }

    }

    if ((colors[0] !== colors[1]) && (showLocations[1]) && (yvals2 !== null)) {

        if (showLines[0]) {
            dataToPlot.push({
                x: (xvals2 !== null) ? xvals2 : xvals,
                y: yvals2,
                width: barWidth,
                base: base2,
                mode: plotMode,
                type: plotType,
                name: "",
                marker: {
                    color: markerColor2,
                    size: markerSize2,
                    symbol: markerSymbol2,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth2
                    }
                },
                line: {
                    color: markerColor2,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                hovertext: hovertext2,
                textposition: "none",
                customdata: customdata2,
            })
        }

        if ((yvals2_b !== null) && (showLines[1])) {
            const hovertemplate2 = '%{text}<br><b>Summer days: </b>%{y:.0f} %'

            dataToPlot.push({
                x: (xvals2 !== null) ? xvals2 : xvals,
                y: yvals2_b,
                mode: plotMode,
                type: plotType,
                name: "",
                marker: {
                    color: markerColor2_b,
                    size: markerSize2,
                    symbol: markerSymbol2,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth2
                    }
                },
                line: {
                    color: markerColor2_b,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }

        if ((yvals2_c !== null) && (showLines[2])) {
            const hovertemplate2 = '%{text}<br><b>Tropical nights: </b>%{y:.0f} %'

            dataToPlot.push({
                x: (xvals2 !== null) ? xvals2 : xvals,
                y: yvals2_c,
                mode: plotMode,
                type: plotType,
                name: "",
                marker: {
                    color: markerColor2_c,
                    size: markerSize2,
                    symbol: markerSymbol2,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth2
                    }
                },
                line: {
                    color: markerColor2_c,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }
    }

    const cartesianLayout = {
        xaxis: {
            showgrid: false,
            fixedrange: true,
            range: xAxisRange,
            tickangle: tickangle,
            tickmode: tickmode, //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
            dtick: dtick,
            zeroline: true,
            tickvals: xvals,
            ticktext: ticktext,
            position: xAxisTickposition,
            tickfont: {
                size: xAxisTickSize,
                weight: 600,
                color: tickColor,
            },
        },
        yaxis: {
            showgrid: true,
            fixedrange: true,
            anchor: 'free',
            position: tickposition,
            visible: yAxisVisible,
            ticklabelposition: ticklabelposition,
            tickfont: {
                weight: 600,
                size: fontSize,
                color: tickColor,
            },
            autorangeoptions: {
                include: include,
                minallowed: minallowed,
            }
        },
        font: {
            size: 12,
            family: 'Lato, sans-serif',
        },
        width: `${width}`,
        height: `${height}`,
        autosize: false,
        showlegend: false,
        margin: {
            b: 35,
            l: 10,
            r: 5,
            t: 0,
        },
        paper_bgcolor: paper_bgcolor,
        plot_bgcolor: '#00000000',
        barcornerradius: barcornerradius,
        bargap: bargap,
        barmode: barmode,
        annotations: annotations,

        shapes: climatologyHighlight,

        hoverlabel: {
            font: {
                family: 'Lato, sans-serif',
            }
        },
    }

    let polarLayout
    if (variable === "Temperature daily climatology") {
        const dataRange = getDataMinMax(yvals, base, yvals2, base2)
        dataToPlot = generatePolarData(yvals, base, yvals2, base2, xvals, dataRange, showLocations, monthIndex)
        polarLayout = generatePolarLayout(width, height, dataRange, tickColor, annotationColor, monthIndex)
    } else if (variable === "Wind annual climatology") {
        dataToPlot = generateWindData(previousData, currentData, showLocations)
        polarLayout = generateWindLayout(width, height, tickColor, annotationColor)
    }

    function handleInitialized() {
        console.log("Plotly plot initialized")
    }

    const layout = isPolar ? polarLayout : cartesianLayout

    return (
        <>
            {isMounted && dataToPlot && layout ? (
                <Plot
                    data={dataToPlot}
                    layout={layout}
                    config={{
                        displayModeBar: false,
                    }}
                    onInitialized={handleInitialized}
                />
            ) : (
                <Plot
                    data={[]}
                    layout={{
                        width: `${width}`,
                        height: `${height}`,
                        xaxis: {
                            showgrid: false,
                            fixedrange: true,
                        },
                        yaxis: {
                            showgrid: false,
                            fixedrange: true,
                        },
                    }}
                    config={{
                        displayModeBar: false,
                    }}
                    style={{ visibility: 'hidden' }}
                />
            )}
        </>
    )
}

export default memo(MakeGraph)